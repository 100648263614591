import axios from 'axios';
import { BASE_URL } from './config';

export const addNewService = async (dataToSend) => {
  const url = `${BASE_URL}Admin-Services/addNewService`;

  try {
    const response = await axios.post(url, dataToSend);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getEstateMarketById = async (id, language) => {
  const url = `${BASE_URL}Admin-Estate-Market/getEstateMarketById/${id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Accept-Language': language,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllServices = async (
  serviceCategoryId,
  searchField,
  pageNumber,
  perPage
) => {
  const filterParam = serviceCategoryId
    ? `&serviceCategoryId=${serviceCategoryId}`
    : '';
  const searchParam = searchField ? `&search=${searchField}` : '';
  const url = `${BASE_URL}Admin-Services/listServices?page=${pageNumber}&perPage=${perPage}${filterParam}${searchParam}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editEstateMarket = async (id, dataToSend) => {
  const url = `${BASE_URL}Admin-Estate-Market/updateEstateMarket`;

  try {
    const response = await axios.put(url, dataToSend);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteService = async (id) => {
  const url = `${BASE_URL}Admin-Services/deleteService/${id}`;

  try {
    const response = await axios.patch(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addServiceFile = async (dataToSend) => {
  const url = `${BASE_URL}Admin-ServiceFile/uploadServiceFile`;

  try {
    const response = await axios.post(url, dataToSend);
    return response;
  } catch (error) {
    throw error;
  }
};

export const listServiceFiles = async (serviceId) => {
  const url = `${BASE_URL}Admin-ServiceFile/listFilesforService/${serviceId}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
