export const showDate = (gotDate) => {
  const getDate = new Date(gotDate);
  const day = getDate.getDate().toString().padStart(2, '0'); // Add zero padding if needed
  const monthIndex = getDate.getMonth(); // Month is zero-based
  const year = getDate.getFullYear();

  return `${day}-${monthIndex + 1}-${year}`; // Month index needs to be incremented by 1
};

// Simulate server latency with default delay of 500ms
export const showResults = async (delay = 500) => {
  await new Promise((resolve) => setTimeout(resolve, delay));
};

export const validatePhoneNumber = (input) => {
  return /^\d+$/.test(input);
};

export const handleChangeLocation = (lat, lng, setState) => {
  const googleLocation = { type: 'Point', coordinates: [lng, lat] };
  setState((prevState) => ({
    ...prevState,
    googleLocation,
  }));
};

export const selectAddons = (e, setState) => {
  const copySelected = [...e];
  setState((prevState) => ({
    ...prevState,
    addonIds: copySelected,
  }));
};

export const handleInputChange = (e, setState) => {
  const { name, value } = e.target;
  setState((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

export const handleDescChange = (e, setState) => {
  const { name, value } = e.target;
  const [field, lang] = name.split('_');
  setState((prevState) => ({
    ...prevState,
    [field]: {
      ...prevState[field],
      [lang]: value,
    },
  }));
};

export const validate = async (value, string, showToast, translate) => {
  if (!value) {
    return showToast(`${translate(string)} ${translate('isRequired')}`, 'warn');
  } else {
    return true;
  }
};
