import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Card, CardBody, Col } from 'reactstrap';

import { useLanguage } from '../../../../i18n/LanguageContext';
import ImageCarousel from '../../../../containers/imageCarousel';
import { UPLOADS_URL } from '../../../../config';

const HorizontalForm = ({ response }) => {
  const { isRTL, translate } = useLanguage();
  const {
    companyName,
    companyLocation,
    companyPhoneNumber,
    serviceCategoryDetails,
    description,
    adminPersonalNotes,
    images,
    mainImage,
    files,
  } = response;

  const renderFormGroup = (label, value) => (
    <div className='form__form-group'>
      <span className='form__form-group-label'>{translate(label)}</span>
      <div className='form__form-group-field'>
        <input value={value} name={label} disabled />
      </div>
    </div>
  );

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className='form form--horizontal'>
            {renderFormGroup(
              'companyName',
              isRTL ? companyName.ar : companyName.en
            )}
            {renderFormGroup('companyLocation', companyLocation)}
            {renderFormGroup('companyPhoneNumber', companyPhoneNumber)}
            {renderFormGroup(
              'serviceCategoryId',
              isRTL
                ? serviceCategoryDetails?.title?.ar
                : serviceCategoryDetails?.title?.en
            )}
            {renderFormGroup(
              'description',
              isRTL ? description.ar : description.en
            )}
            {renderFormGroup('adminPersonalNotes', adminPersonalNotes)}

            <h5 className='bold-text mb-4'>{translate('serviceMainImage')}</h5>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {mainImage?.length > 0 ? (
                <img
                  src={`${UPLOADS_URL}uploads/${mainImage}`}
                  alt=''
                  style={{ width: '40%', height: 'auto' }}
                />
              ) : (
                `${translate('noImages')}`
              )}
            </div>

            <h5 className='bold-text mb-4'>{translate('serviceImages')}</h5>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              className='bg-light p-3'
            >
              {images?.length > 0 ? (
                <ImageCarousel images={images} />
              ) : (
                `${translate('noImages')}`
              )}
            </div>

            <h5 className='bold-text mb-4'>{translate('serviceFiles')}</h5>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                margin: '0 0 20px 0',
                alignItems: 'center',
              }}
              className='bg-light p-3'
            >
              {files && files.length > 0 ? (
                <ul className='list-group w-50'>
                  {files.map((contract, index) => (
                    <li key={index} className='list-group-item'>
                      <a
                        href={`${UPLOADS_URL}uploads/${contract.fileName}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='list-group-item list-group-item-action'
                      >
                        {contract.title}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{translate('noFiles')}</p>
              )}
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
