import React from 'react';
import LogInForm from './components/LogInForm';
import { useLanguage } from '../../i18n/LanguageContext';

const LogIn = () => {
  const { isRTL, language, translate } = useLanguage();

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              {translate('welcome1')}
              <span className="account__logo">
                {' '}
                {translate('welcome2')}
                <span className="account__logo-accent">
                  {' '}
                  {translate('welcome3')}
                </span>
              </span>
            </h3>
          </div>
          <LogInForm onSubmit />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
