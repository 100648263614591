import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import HorizontalForm from './components';
import { useLanguage } from '../../../i18n/LanguageContext';
import { showResults } from '../../../utils/functions';

const EditForm = () => {
  const { translate } = useLanguage();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className='page-title'>{translate('editService')}</h3>
        </Col>
      </Row>
      <Row>
        <HorizontalForm onSubmit={showResults} />
      </Row>
    </Container>
  );
};

EditForm.propTypes = {
  t: PropTypes.func,
};

export default EditForm;
