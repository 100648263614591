import React, { Component } from "react";
import { TailSpin } from "react-loader-spinner";
export default class Loader extends Component {
  render() {
    return (
      <div
        //className="loader-class"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // backgroundColor: " rgba(0, 0, 0, 0.5)",
          zIndex: 200,
        }}
      >
        <TailSpin type="Bars" color="#19295F" height={50} width={50} />
      </div>
    );
  }
}
