import React, { useState } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';

import * as Config from '../../../../../config';
import { useLanguage } from '../../../../../i18n/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../../../utils/toast';
import { sessionExpire } from '../../../../../redux/actions/authAction';

const HorizontalForm = ({ handleSubmit, reset }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRTL, translate, language } = useLanguage();
  const [formData, setFormData] = useState({
    key: '',
  });

  const _onAddItemHandeller = async () => {
    let dataToSend = {
      key: formData.key,
    };

    axios
      .post(`${Config.BASE_URL}Admin-FilterKey/addFilterKey`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          showToast(translate('addedSuccessfully'), 'success');
          setFormData({
            key: '',
          });
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className='form form--horizontal' onSubmit={handleSubmit}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>{translate('key')}</span>
              <div className='form__form-group-field'>
                <input
                  value={formData?.key}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      key: e.target.value,
                    })
                  }
                  name='key'
                  component='input'
                  type='text'
                  placeholder={translate('enterKeyTitle')}
                />
              </div>
            </div>

            <ButtonToolbar className='form__button-toolbar'>
              <Button
                color='primary'
                disabled={!formData.key}
                onClick={_onAddItemHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(HorizontalForm);
