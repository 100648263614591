import React from 'react';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import * as Config from '../../../../config';

const RequestItem = ({ request, onSellRent }) => {
  const { translate, language } = useLanguage();

  return (
    <>
      <div className="property-requests-table">
        <Link to={`/property/3/${request?.requestStatusId}/${request._id}`}>
          <div className="d-flex">
            <img
              src={
                request?.mainImage
                  ? `${Config.UPLOADS_URL}uploads/${request?.mainImage}`
                  : require('../../../../assets/images/icons/avatar.jpg')
              }
              alt="profile"
              style={{
                borderRadius: '10%',
                margin: '10px',
                width: '70px',
                height: '70px',
                marginLeft: '30px',
              }}
            />
            <Table responsive style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>
                    {language === 'en'
                      ? request?.userDetails?.nameEn
                      : request?.userDetails?.nameAr}
                  </th>
                  <th>{translate('governorate')}</th>
                  <th>{translate('area')}</th>
                  <th>{translate('block')}</th>
                  <th>{translate('streetTitle')}</th>
                  <th>{translate('homeTitle')}</th>
                  <th>{translate('address')}</th>
                  <th>{translate('referenceTitle')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{request?.userDetails?.phoneNumber}</p>
                  </td>
                  <td>{request?.governorateDetails?.title?.[language]}</td>
                  <td>{request?.areaDetails?.title?.[language]}</td>
                  <td>{request?.block}</td>
                  <td>{request?.streetNo}</td>
                  <td>{request?.homeNo}</td>
                  <td>{request?.location}</td>
                  <td>{request?.referenceNo}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Link>
      </div>
      <div
        className="line"
        style={{
          width: '97%',
          border: '1px solid rgba(96, 96, 96, 0.1)',
          margin: '10px auto',
        }}
      />
    </>
  );
};

export default RequestItem;
