import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useLanguage } from '../../../i18n/LanguageContext';

const SidebarLink = ({ title, icon, newLink, route, onClick }) => {
  const { isRTL } = useLanguage();

  return (
    <NavLink
      to={route}
      onClick={onClick}
      // activeClassName="sidebar__link-active"
    >
      <li className={isRTL ? 'sidebar__link__rtl' : 'sidebar__link__ltr'}>
        {icon ? (
          <span
            className={`${
              isRTL ? 'sidebar__link-icon__rtl' : 'sidebar__link-icon__ltr'
            } lnr lnr-${icon}`}
          />
        ) : (
          ''
        )}
        <p className='sidebar__link-title'>
          {title}
          {newLink ? (
            <Badge className='sidebar__link-badge'>
              <span>New</span>
            </Badge>
          ) : (
            ''
          )}
        </p>
      </li>
    </NavLink>
  );
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
