// eslint-disable-next-line import/no-extraneous-dependencies
import React, { Component, Fragment } from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { ToastContainer } from 'react-toastify';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
// import ScrollToTop from './ScrollToTop';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    // Set a timeout as a fallback to ensure `loaded` is set to true after 1 second max
    const loadFallbackTimeout = setTimeout(() => {
      this.setState({ loaded: true });
    }, 1000);

    window.addEventListener('load', () => {
      clearTimeout(loadFallbackTimeout); // clear the fallback if load event fires correctly
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          {/* <ScrollToTop> */}
          <Fragment>
            {!loaded && (
              <div className={`load${loading ? '' : ' loaded'}`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path
                      fill="#6094a8"
                      d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                    />
                  </svg>
                </div>
              </div>
            )}
            <div>
              <Router />
            </div>
          </Fragment>
          {/* </ScrollToTop> */}
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    );
  }
}

export default hot(module)(App);
