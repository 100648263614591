import { Outlet } from 'react-router-dom';
import Layout from '../Layout/index';

import { useLanguage } from '../../i18n/LanguageContext';

const ScreenWrapper = () => {
  const { isRTL } = useLanguage();
  return (
    <div>
      <Layout />
      <div className={isRTL ? 'container__wrap__rtl' : 'container__wrap__ltr'}>
        {/* Render the nested routes */}
        <Outlet />
      </div>
    </div>
  );
};

export default ScreenWrapper;
