import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';

function Search({ placeholder, searchField, setSearchField, search }) {
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchField(query);
    }, 500);
    return () => clearTimeout(timer);
  }, [query]);

  return (
    <div className={`input-container ${isFocused ? 'focused' : ''}`}>
      <input
        type={'text'}
        onChange={(x) => setQuery(x.target.value)}
        value={query}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className='input-element'
      />
    </div>
  );
}

export default reduxForm({
  form: 'horizontal_form',
})(Search);
