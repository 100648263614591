import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useNavigate } from 'react-router-dom';
import MapPicker from 'react-google-map-picker';
import { useDispatch, useSelector } from 'react-redux';
import 'react-dropzone-uploader/dist/styles.css';

import {
  requestPurposeAr,
  requestPurposeEn,
} from '../../../../utils/allDropdownData';
import * as Config from '../../../../config';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { showToast } from '../../../../utils/toast';
import { LookupsAPI } from '../../../../apis';
import useErrorHandling from '../../../../utils/error';
import { setLookupsData } from '../../../../redux/actions/authAction';

export const BOOLEANS = [
  { title: { en: 'Yes', ar: 'نعم' }, value: true },
  { title: { en: 'No', ar: 'لا' }, value: false },
];

const HorizontalForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const { isRTL, translate, language } = useLanguage();

  const lookups = useSelector((state) => state.auth.lookups);

  const [zoom, setZoom] = useState(8);
  const [isFirstLaunch, setIsFirstLaunch] = useState(true);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [priceNatureStatuses, setPriceNatureStatuses] = useState([]);
  const [areas, setAreas] = useState([]);

  const [state, setState] = useState({
    requestStatusId: 1,
    priceNature: null,
    requestTypeId: null,
    propertyTypeId: null,
    governorateId: null,
    areaId: null,
    block: null,
    price: null,
    areaSM: null,
    googleLocation: {
      type: 'Point',
      coordinates: {
        default: [47.4818, 29.3117],
      },
    },
    rentedOrSoldPrice: null,
    rentedOrSoldPriceMS: null,
    rentedOrSoldDate: null,
  });

  useEffect(() => {
    if (lookups && state?.requestTypeId) {
      const list = [];
      lookups?.propertyTypes?.forEach((element) => {
        if (
          (state?.requestTypeId === 1 && element.rentStatus === 1) ||
          (state?.requestTypeId === 2 && element.sellStatus === 1)
        ) {
          list.push({
            label: element?.title?.[language === 'en' ? 'en' : 'ar'],
            value: element?._id,
            adminFilterKeys: element?.adminFilterKeys,
          });
        }
      });
      setPropertyTypes(list);
    }
  }, [language, lookups, state?.requestTypeId]);

  useEffect(() => {
    if (
      (lookups && governorates?.length === 0 && lookups?.governorates) ||
      (state?.governorateId && lookups?.governorates)
    ) {
      const list = [];
      const secondList = [];
      lookups?.governorates?.forEach((element) => {
        list.push({
          label: element?.title?.[language === 'en' ? 'en' : 'ar'],
          value: element?._id,
        });

        if (element?._id === state?.governorateId) {
          element?.areas?.forEach((item) => {
            secondList.push({
              label: item?.title?.[language === 'en' ? 'en' : 'ar'],
              value: item?._id,
            });
          });
        }
      });
      setAreas(secondList);
      setGovernorates(list);
    }
  }, [lookups, governorates, state?.governorateId]);

  useEffect(() => {
    if (lookups && priceNatureStatuses?.length === 0) {
      const list = [];
      lookups?.priceNatureStatuses?.forEach((element) => {
        list.push({
          label: element?.title?.[language === 'en' ? 'en' : 'ar'],
          value: element?.id,
        });
      });
      setPriceNatureStatuses(list);
    }
  }, [lookups, priceNatureStatuses]);

  const onChangeGovernorate = (e) => {
    setState({ ...state, governorateId: e.value });
    const secondList = [];
    lookups?.governorates?.forEach((element) => {
      if (element?._id === e.value) {
        element?.areas?.forEach((item) => {
          secondList.push({
            label: item?.title?.[language === 'en' ? 'en' : 'ar'],
            value: item?._id,
          });
        });
        setAreas(secondList);
      }
    });
  };

  const validate = async (value, string) => {
    if (!value) {
      return showToast(
        `${translate(string)} ${translate('isRequired')}`,
        'warn'
      );
    } else {
      return true;
    }
  };

  const activateRequest = async () => {
    let body = {
      ...state,
    };

    if (!(await validate(body?.requestTypeId, 'requestPurpose'))) {
      return false;
    }
    if (!(await validate(body?.propertyTypeId, 'propertyType'))) {
      return false;
    }
    if (!(await validate(body?.governorateId, 'governorate'))) {
      return false;
    }
    if (!(await validate(body?.areaId, 'area'))) {
      return false;
    }
    if (!(await validate(body?.price, 'price'))) {
      return false;
    }
    // if (!(await validate(body?.priceNature, 'priceNature'))) {
    //   return false;
    // }
    if (!(await validate(body?.areaSM, 'areaSM'))) {
      return false;
    }
    if (!(await validate(body?.rentedOrSoldPrice, 'rentedOrSoldPrice'))) {
      return false;
    }
    if (!(await validate(body?.rentedOrSoldPriceMS, 'rentedOrSoldPriceMS'))) {
      return false;
    }
    axios
      .post(`${Config.BASE_URL}Admin-Estate-Market/addEstateMarket`, body)
      .then((response) => {
        if (response.status === 200) {
          navigate('/estateMarket/list');
          showToast(translate('success'), 'success');
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleChangeLocation = (lat, lng) => {
    const googleLocation = { type: 'Point', coordinates: [lng, lat] };
    setState((prevState) => ({
      ...prevState,
      googleLocation,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <h4 className="text-justify row">{translate('requestDetails')}</h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('requestPurpose')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="propertyTypeDetails"
                    className="react-select"
                    placeholder={''}
                    options={isRTL ? requestPurposeAr : requestPurposeEn}
                    value={
                      isRTL
                        ? requestPurposeAr.find(
                            (item) => item.value === state.requestTypeId
                          )
                        : requestPurposeEn.find(
                            (item) => item.value === state.requestTypeId
                          )
                    }
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        requestTypeId: e.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('propertyType')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="propertyTypeId"
                    className="react-select"
                    placeholder={''}
                    options={propertyTypes}
                    value={propertyTypes.find(
                      (item) => item.value === state.propertyTypeId
                    )}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        propertyTypeId: e.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <h4 className="text-justify row">
              {translate('locationPropertyDetails')}
            </h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('governorate')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="governorateId"
                    className="react-select"
                    placeholder={''}
                    options={governorates}
                    value={governorates.find(
                      (item) => item.value === state.governorateId
                    )}
                    onChange={(e) => onChangeGovernorate(e)}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('area')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="areaId"
                    className="react-select"
                    placeholder={''}
                    options={areas}
                    value={areas.find((item) => item.value === state.areaId)}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        areaId: e.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('block')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.block}
                    onChange={handleInputChange}
                    name="block"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <h4 className="text-justify row">
              {translate('generalPropertyCharacteristics')}
            </h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('price')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.price}
                    onChange={handleInputChange}
                    name="price"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('rentedOrSoldPrice')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.rentedOrSoldPrice}
                    onChange={handleInputChange}
                    name="rentedOrSoldPrice"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('priceNature')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="priceNature"
                    className="react-select"
                    placeholder={''}
                    options={priceNatureStatuses}
                    value={priceNatureStatuses.find(
                      (item) => item.value === state.priceNature
                    )}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        priceNature: e.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('areaSM')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.areaSM}
                    onChange={handleInputChange}
                    name="areaSM"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('rentedOrSoldPriceMS')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.rentedOrSoldPriceMS}
                    onChange={handleInputChange}
                    name="rentedOrSoldPriceMS"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            {/* <h4 className="text-justify row">{translate('googleLocation')}</h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('longitude')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.googleLocation?.coordinates?.[1]}
                    disabled
                    // onChange={(e) =>
                    //   setState({
                    //     ...state,
                    //     googleLocation: {
                    //       ...state?.googleLocation,
                    //       coordinates: [
                    //         e.value,
                    //         state?.googleLocation?.coordinates?.[1],
                    //       ],
                    //     },
                    //   })
                    // }
                    // name="longitude"
                    // component="input"
                    // type="number"
                    // placeholder={''}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('latitude')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.googleLocation?.coordinates?.[0]}
                    disabled
                    // onChange={(e) =>
                    //   setState({
                    //     ...state,
                    //     googleLocation: {
                    //       ...state?.googleLocation,
                    //       coordinates: [
                    //         state?.googleLocation?.coordinates?.[0],
                    //         e.value,
                    //       ],
                    //     },
                    //   })
                    // }
                    // name="latitude"
                    // component="input"
                    // type="number"
                    // placeholder={''}
                  />
                </div>
              </div>
              <MapPicker
                defaultLocation={{
                  lat: state?.googleLocation?.coordinates?.[1] || 29.3117,
                  lng: state?.googleLocation?.coordinates?.[0] || 47.4818,
                }}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: '400px', width: '400px' }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={setZoom}
                apiKey="AIzaSyAw90ySnVcKpKWvYQKB7EgIUMo_8hPpTZ4"
              />
            </div> */}
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" onClick={activateRequest}>
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(HorizontalForm);
