/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';

import * as Config from '../../../../config/index';
import Panel from '../../../../shared/components/CollapsedPanel';
import Search from '../../../../shared/components/Search';
import Loader from '../../../../containers/Loader/index';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { showDate } from '../../../../utils/functions';
import { showToast } from '../../../../utils/toast';
import { sessionExpire } from '../../../../redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestPurposeAr,
  requestPurposeEn,
} from '../../../../utils/allDropdownData';

const DataTable = ({ governorate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRTL, translate, language } = useLanguage();
  const [state, setState] = useState({
    id: '',
    items: [],
    pageRange: 5,
    page: 1,
    perPage: 10,
    totalItemCount: 0,
    alertStatus: false,
    alertDelete: false,
    searchField: '',
    isLoading: false,
  });

  useEffect(() => {
    itemsListing(state.searchField, state.page);
  }, []);

  const itemsListing = async (searchField, page) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const searchParam = searchField ? `&search=${searchField}` : '';
    const url = `${Config.BASE_URL}Admin-Estate-Market/listEstateMarket?page=${page}&perPage=${state.perPage}&governorateId=${governorate?._id}${searchParam}`;

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            items: response.data.data.listing,
            totalItemCount: response.data.data.count,
            isLoading: false,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  const onActiveInnactive = async (id) => {
    setState((prevState) => ({
      ...prevState,
      alertStatus: true,
      id: id,
    }));
  };

  const onDeleteHandeler = async (id) => {
    setState((prevState) => ({
      ...prevState,
      alertDelete: true,
      id: id,
    }));
  };

  const onConfirmStatus = async (val) => {
    if (val) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const url = `${Config.BASE_URL}Admin-Estate-Market/activeInnactiveEstateMarket/${state.id}`;
      axios
        .patch(url)
        .then((response) => {
          itemsListing(state.searchField, state.page);
          setState((prevState) => ({
            ...prevState,
            alertStatus: false,
            isLoading: false,
          }));
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            alertStatus: false,
          }));
          if (!error.response) {
            return showToast(String(error), 'error');
          } else if (error.response.status === 401) {
            showToast(translate('sessionExpire'), 'error');
            dispatch(sessionExpire());
          } else {
            return error.response.data.message
              ? showToast(error.response.data.message, 'error')
              : showToast(String(error), 'error');
          }
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        alertStatus: false,
      }));
    }
  };

  const onConfirmDelete = async (val) => {
    if (val) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const url = `${Config.BASE_URL}Admin-Estate-Market/deleteEstateMarket/${state.id}`;
      axios
        .patch(url)
        .then((response) => {
          if (response.status === 200) {
            itemsListing(state.searchField, state.page);
            setState((prevState) => ({
              ...prevState,
              alertDelete: false,
              isLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              isLoading: false,
              alertDelete: false,
            }));
            return response.data.message
              ? showToast(response.data.message, 'error')
              : showToast(translate('error'), 'error');
          }
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            alertDelete: false,
          }));
          if (!error.response) {
            return showToast(String(error), 'error');
          } else if (error.response.status === 401) {
            showToast(translate('sessionExpire'), 'error');
            dispatch(sessionExpire());
          } else {
            return error.response.data.message
              ? showToast(error.response.data.message, 'error')
              : showToast(String(error), 'error');
          }
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        alertDelete: false,
      }));
    }
  };

  const handlePageChange = (pageNumber) => {
    setState((prevState) => ({ ...prevState, page: pageNumber }));
    itemsListing(state.searchField, pageNumber);
  };

  return (
    <div className="card">
      <div className="content">
        <Panel
          xl={12}
          lg={12}
          md={12}
          xs={12}
          title={
            language === 'en' ? governorate.title.en : governorate.title.ar
          }
          subhead=""
          refreshFunc={() => itemsListing(state.searchField, state.page)}
          addFunc={() => {
            navigate('/estateMarket/add');
          }}
          placement={isRTL ? 'rtl' : 'ltr'}
          withAddFunc={false}
          withCollapse={true}
        >
          <div dir={isRTL ? 'rtl' : 'ltr'}>
            {state.isLoading && <Loader />}
            <ResponsiveContainer className="dashboard__area">
              <table className="table table-striped ">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{translate('requestPurpose')}</th>
                    <th>{translate('propertyType')}</th>
                    <th>{translate('governorate')}</th>
                    <th>{translate('area')}</th>
                    <th>{translate('space')}</th>
                    <th>{translate('rentedOrSoldPrice')}</th>
                    <th>
                      <div className="table-search cursor-pointer">
                        {translate('createdAt')}
                      </div>
                    </th>
                    <th>{translate('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {state.items.length > 0 &&
                    state.items.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {i +
                              (state.page * state.perPage - state.perPage) +
                              1}
                          </td>
                          <td>
                            {isRTL
                              ? requestPurposeAr.find(
                                  (item) => item.value === val?.requestTypeId
                                )?.label || ''
                              : requestPurposeEn.find(
                                  (item) => item.value === val?.requestTypeId
                                )?.label || ''}
                          </td>
                          <td>{val?.propertyTypeDetails?.[language]}</td>
                          <td>{val?.governorateDetails?.[language]}</td>
                          <td>{val?.areaDetails?.[language]}</td>
                          <td>{val?.areaSM}</td>
                          <td>{val?.rentedOrSoldPrice}</td>
                          <td>{showDate(val.createdAt)}</td>
                          <td>
                            <Link
                              to={{
                                pathname: '/estateMarket/view',
                                search: `?id=${val._id}`,
                              }}
                            >
                              <IconButton
                                className="text-primary"
                                aria-label="Edit"
                              >
                                <i title="Edit" className="icon-eye"></i>
                              </IconButton>
                            </Link>
                            <Link
                              to={{
                                pathname: '/estateMarket/edit',
                                search: `?id=${val._id}`,
                              }}
                            >
                              <IconButton
                                className="text-primary"
                                aria-label="Edit"
                              >
                                <i title="Edit" className="icon-pencil"></i>
                              </IconButton>
                            </Link>
                            <IconButton
                              onClick={() => onActiveInnactive(val._id)}
                              className={
                                val.status === 1 ? 'text-info' : 'text-danger'
                              }
                              aria-label="Edit"
                            >
                              <span
                                title={val.status === 2 ? 'Unblock' : 'Block'}
                                className="material-icons mr-10"
                              >
                                {val.status === 2 ? 'lock' : 'lock_open'}
                              </span>
                            </IconButton>
                            <IconButton
                              onClick={() => onDeleteHandeler(val._id)}
                              className="text-danger"
                              aria-label="Edit"
                            >
                              <span
                                className="glyphicon glyphicon-trash"
                                title="delete"
                              ></span>
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </ResponsiveContainer>

            <div className="row">
              <div className="col-md-12">
                <Pagination
                  activePage={state.page}
                  itemsCountPerPage={state.perPage}
                  totalItemsCount={state.totalItemCount}
                  pageRangeDisplayed={state.pageRange}
                  onChange={(n) => handlePageChange(n)}
                />
              </div>
            </div>
          </div>
        </Panel>
        <SweetAlert
          info
          showCancel
          custom
          confirmBtnText={translate('yes')}
          show={state.alertStatus}
          cancelBtnText={translate('no')}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          btnSize="sm"
          title={translate('changeStatusAlert')}
          onConfirm={() => onConfirmStatus(true)}
          onCancel={() => onConfirmStatus(false)}
        ></SweetAlert>
        <SweetAlert
          danger
          showCancel
          custom
          confirmBtnText={translate('yes')}
          show={state.alertDelete}
          cancelBtnText={translate('no')}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          btnSize="sm"
          title={translate('deleteAlert')}
          onConfirm={() => onConfirmDelete(true)}
          onCancel={() => onConfirmDelete(false)}
        ></SweetAlert>
      </div>
    </div>
  );
};

export default DataTable;
