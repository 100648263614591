import axios from 'axios';
import { BASE_URL } from './config';

export const addAdmin = async (data) => {
  const url = `${BASE_URL}Admin-Admins/addAdmin`;

  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdminById = async (id) => {
  const url = `${BASE_URL}Admin-Admins/${id}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editAdmin = async (data) => {
  const url = `${BASE_URL}Admin-Admins/editAdmin`;

  try {
    const response = await axios.patch(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllAdmins = async (searchField, page, perPage) => {
  const searchParam = searchField ? `&search=${searchField}` : '';
  const url = `${BASE_URL}Admin-Admins/listAdmins?page=${page}&perPage=${perPage}${searchParam}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const activateDeactivateAdmin = async (id) => {
  const url = `${BASE_URL}Admin-Admins/activeInnactiveAdmin/${id}`;

  try {
    const response = await axios.patch(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAdmin = async (id) => {
  const url = `${BASE_URL}Admin-Admins/deleteAdmin/${id}`;

  try {
    const response = await axios.patch(url);
    return response;
  } catch (error) {
    throw error;
  }
};
