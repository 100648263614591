import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import MapPicker from 'react-google-map-picker';

import { useLanguage } from '../../../../i18n/LanguageContext';
import { MarketAPI } from '../../../../apis';
import useErrorHandling from '../../../../utils/error';
import { showToast } from '../../../../utils/toast';
import {
  requestPurposeAr,
  requestPurposeEn,
} from '../../../../utils/allDropdownData';
const HorizontalForm = ({ handleSubmit }) => {
  const url_string = document.URL;
  const url = new URL(url_string);
  const id = url.searchParams.get('id');

  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const { isRTL, translate, language } = useLanguage();

  const [zoom, setZoom] = useState(8);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [priceNatureStatuses, setPriceNatureStatuses] = useState([]);
  const [areas, setAreas] = useState([]);

  const lookups = useSelector((state) => state.auth.lookups);

  const [formData, setFormData] = useState({
    _id: '',
    priceNature: null,
    requestTypeId: null,
    propertyTypeId: null,
    governorateId: null,
    areaId: null,
    block: null,
    price: null,
    areaSM: null,
    googleLocation: {
      type: 'Point',
      coordinates: {
        default: [47.4818, 29.3117],
      },
    },
    rentedOrSoldPrice: null,
    rentedOrSoldPriceMS: null,
  });

  useEffect(() => {
    if (lookups && formData?.requestTypeId) {
      const list = [];
      lookups?.propertyTypes?.forEach((element) => {
        if (
          (formData?.requestTypeId === 1 && element.rentStatus === 1) ||
          (formData?.requestTypeId === 2 && element.sellStatus === 1)
        ) {
          list.push({
            label: element?.title?.[language === 'en' ? 'en' : 'ar'],
            value: element?._id,
          });
        }
      });
      setPropertyTypes(list);
    }
  }, [language, lookups, formData?.requestTypeId]);

  useEffect(() => {
    if (
      (lookups && governorates?.length === 0 && lookups?.governorates) ||
      (formData?.governorateId && lookups?.governorates)
    ) {
      const list = [];
      const secondList = [];
      lookups?.governorates?.forEach((element) => {
        list.push({
          label: element?.title?.[language === 'en' ? 'en' : 'ar'],
          value: element?._id,
        });

        if (element?._id === formData?.governorateId) {
          element?.areas?.forEach((item) => {
            secondList.push({
              label: item?.title?.[language === 'en' ? 'en' : 'ar'],
              value: item?._id,
            });
          });
        }
      });
      setAreas(secondList);
      setGovernorates(list);
    }
  }, [lookups, governorates, formData?.governorateId]);

  useEffect(() => {
    if (lookups && priceNatureStatuses?.length === 0) {
      const list = [];
      lookups?.priceNatureStatuses?.forEach((element) => {
        list.push({
          label: element?.title?.[language === 'en' ? 'en' : 'ar'],
          value: element?.id,
        });
      });
      setPriceNatureStatuses(list);
    }
  }, [lookups, priceNatureStatuses]);

  const onChangeGovernorate = (e) => {
    setFormData({ ...formData, governorateId: e.value });
    const secondList = [];
    lookups?.governorates?.forEach((element) => {
      if (element?._id === e.value) {
        element?.areas?.forEach((item) => {
          secondList.push({
            label: item?.title?.[language === 'en' ? 'en' : 'ar'],
            value: item?._id,
          });
        });
        setAreas(secondList);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MarketAPI.getEstateMarketById(id, language);
        setFormData({ ...response.data.data });
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    fetchData();
  }, [id, language]);

  const _onEditHandeller = async () => {
    const dataToSend = {
      _id: formData._id,
      priceNature: formData.priceNature,
      requestTypeId: formData.requestTypeId,
      propertyTypeId: formData.propertyTypeId,
      governorateId: formData.governorateId,
      areaId: formData.areaId,
      block: formData.block,
      price: formData.price,
      areaSM: formData.areaSM,
      googleLocation: formData.googleLocation,
      rentedOrSoldPrice: formData.rentedOrSoldPrice,
      rentedOrSoldPriceMS: formData.rentedOrSoldPriceMS,
    };

    try {
      const response = await MarketAPI.editEstateMarket(id, dataToSend);

      if (response.status === 200) {
        showToast(translate('updatedSuccessfully'), 'success');
        navigate(`/estateMarket/list`);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeLocation = (lat, lng) => {
    const googleLocation = { type: 'Point', coordinates: [lng, lat] };
    setFormData((prevState) => ({
      ...prevState,
      googleLocation,
    }));
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <h4 className="text-justify row">{translate('requestDetails')}</h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('requestPurpose')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="propertyTypeDetails"
                    className="react-select"
                    placeholder={''}
                    options={isRTL ? requestPurposeAr : requestPurposeEn}
                    value={
                      isRTL
                        ? requestPurposeAr.find(
                            (item) => item.value === formData.requestTypeId
                          )
                        : requestPurposeEn.find(
                            (item) => item.value === formData.requestTypeId
                          )
                    }
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        requestTypeId: e.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('propertyType')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="propertyTypeId"
                    className="react-select"
                    placeholder={''}
                    options={propertyTypes}
                    value={propertyTypes.find(
                      (item) => item.value === formData.propertyTypeId
                    )}
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        propertyTypeId: e.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <h4 className="text-justify row">
              {translate('locationPropertyDetails')}
            </h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('governorate')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="governorateId"
                    className="react-select"
                    placeholder={''}
                    options={governorates}
                    value={governorates.find(
                      (item) => item.value === formData.governorateId
                    )}
                    onChange={(e) => onChangeGovernorate(e)}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('area')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="areaId"
                    className="react-select"
                    placeholder={''}
                    options={areas}
                    value={areas.find((item) => item.value === formData.areaId)}
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        areaId: e.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('block')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={formData?.block}
                    onChange={handleInputChange}
                    name="block"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <h4 className="text-justify row">
              {translate('generalPropertyCharacteristics')}
            </h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('price')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={formData?.price}
                    onChange={handleInputChange}
                    name="price"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('rentedOrSoldPrice')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={formData?.rentedOrSoldPrice}
                    onChange={handleInputChange}
                    name="rentedOrSoldPrice"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('priceNature')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="priceNature"
                    className="react-select"
                    placeholder={''}
                    options={priceNatureStatuses}
                    value={priceNatureStatuses.find(
                      (item) => item.value === formData.priceNature
                    )}
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        priceNature: e.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('areaSM')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={formData?.areaSM}
                    onChange={handleInputChange}
                    name="areaSM"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('rentedOrSoldPriceMS')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={formData?.rentedOrSoldPriceMS}
                    onChange={handleInputChange}
                    name="rentedOrSoldPriceMS"
                    component="input"
                    type="number"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            {/* <h4 className="text-justify row">{translate('googleLocation')}</h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('longitude')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={formData?.googleLocation?.coordinates?.[1]}
                    disabled
                    // onChange={(e) =>
                    //   setState({
                    //     ...state,
                    //     googleLocation: {
                    //       ...state?.googleLocation,
                    //       coordinates: [
                    //         e.value,
                    //         state?.googleLocation?.coordinates?.[1],
                    //       ],
                    //     },
                    //   })
                    // }
                    // name="longitude"
                    // component="input"
                    // type="number"
                    // placeholder={''}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('latitude')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={formData?.googleLocation?.coordinates?.[0]}
                    disabled
                    // onChange={(e) =>
                    //   setState({
                    //     ...state,
                    //     googleLocation: {
                    //       ...state?.googleLocation,
                    //       coordinates: [
                    //         state?.googleLocation?.coordinates?.[0],
                    //         e.value,
                    //       ],
                    //     },
                    //   })
                    // }
                    // name="latitude"
                    // component="input"
                    // type="number"
                    // placeholder={''}
                  />
                </div>
              </div>
              <MapPicker
                defaultLocation={{
                  lat: formData?.googleLocation?.coordinates?.[1] || 29.3117,
                  lng: formData?.googleLocation?.coordinates?.[0] || 47.4818,
                }}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: '400px', width: '400px' }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={setZoom}
                apiKey="AIzaSyAw90ySnVcKpKWvYQKB7EgIUMo_8hPpTZ4"
              />
            </div> */}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.governorateId ||
                  !formData.areaId ||
                  !formData.propertyTypeId ||
                  !formData.price ||
                  !formData.priceNature ||
                  !formData.areaSM ||
                  !formData.rentedOrSoldPrice ||
                  !formData.rentedOrSoldPriceMS ||
                  !formData.requestTypeId
                }
                onClick={_onEditHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
