import axios from 'axios';
import {
  SET_IS_LOGGED_USER,
  SET_LOGGED_USER_DATA,
  LOGOUT,
  SET_LOOKUP_DATA,
} from './ActionTypes';

export const setIsLoggedIn = (isLoggedIn) => {
  return {
    type: SET_IS_LOGGED_USER,
    isLoggedIn,
  };
};

export const setLoggedUserData = (data) => {
  return {
    type: SET_LOGGED_USER_DATA,
    data,
  };
};

export const setLookupsData = (data) => {
  return {
    type: SET_LOOKUP_DATA,
    data,
  };
};

export const logout = () => {
  delete axios.defaults.headers.common['Authorization'];
  localStorage.removeItem('loggedData');
  return {
    type: LOGOUT,
  };
};

export const sessionExpire = () => {
  delete axios.defaults.headers.common['Authorization'];
  localStorage.removeItem('loggedData');
  return {
    type: LOGOUT,
  };
};
