import axios from 'axios';
import { BASE_URL } from './config';

export const getAllEstates = async ({
  status = '',
  searchField = '',
  pageNumber = 1,
  perPage = 10,
}) => {
  const statusParam = status ? `status=${status}` : '';
  const pageParam = pageNumber ? `&page=${pageNumber}` : '';
  const perPageParam = perPage ? `&perPage=${perPage}` : '';
  const searchParam = searchField ? `&search=${searchField}` : '';

  const queryParams = `${statusParam}${pageParam}${perPageParam}${searchParam}`;

  const finalQueryParams = queryParams.startsWith('&')
    ? queryParams.substring(1)
    : queryParams;

  const url = `${BASE_URL}Admin-Estate/listEstates?${finalQueryParams}`;
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserEstates = async (id) => {
  const url = `${BASE_URL}Admin-Estate/listUserEstates/${id}`;
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
