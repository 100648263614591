import React, { useState, useEffect } from 'react';

import { FilterKeyAPI } from '../../apis';
import { showToast } from '../../utils/toast';
import useErrorHandling from '../..//utils/error';
import { useLanguage } from '../../i18n/LanguageContext';
import { COMMON_STATUS } from '../../utils/allDropdownData';

const FilterKeys = ({
  title,
  status,
  filterKeyIds,
  filterKeyType,
  changeStatus,
  handleCheckboxChange,
}) => {
  const { translate } = useLanguage();
  const handleError = useErrorHandling();

  const [filterKeys, setFilterKeys] = useState([]);

  const getAllFilterKeys = async () => {
    try {
      const response = await FilterKeyAPI.getAllFilterKeys(
        COMMON_STATUS.ACTIVE
      );
      if (response.status === 200) {
        setFilterKeys(response.data.data.listing);
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAllFilterKeys();
  }, []);

  return (
    <div>
      <div className='form__form-group'>
        <div className='form__form-group-field'>
          <div className='checkbox-container-title'>
            {status && (
              <input
                name='checkbox_status'
                type='checkbox'
                checked={status === COMMON_STATUS.ACTIVE ? true : false}
                onChange={changeStatus}
              />
            )}
            <label>{translate(title)}</label>
          </div>
        </div>
      </div>
      {filterKeys &&
        filterKeys.map(({ _id, key }) => (
          <div className='form__form-group' key={_id}>
            <div className='form__form-group-field'>
              <div
                className={
                  status === COMMON_STATUS.INACTIVE
                    ? 'checkbox-container disabled'
                    : 'checkbox-container'
                }
              >
                <input
                  name={`checkbox_${_id}`}
                  type='checkbox'
                  checked={filterKeyIds?.includes(_id)}
                  onChange={() => handleCheckboxChange(filterKeyType, _id)}
                  disabled={status === COMMON_STATUS.INACTIVE}
                />
                <label>{key}</label>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FilterKeys;
