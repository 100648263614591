import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';

import * as Config from '../../../../../config';
import { useLanguage } from '../../../../../i18n/LanguageContext';
import { showToast } from '../../../../../utils/toast';
import {
  sessionExpire,
  setLookupsData,
} from '../../../../../redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { LookupsAPI } from '../../../../../apis';

const HorizontalForm = ({ handleSubmit, reset, t }) => {
  const dispatch = useDispatch();
  const { isRTL, translate, language } = useLanguage();
  const [formData, setFormData] = useState({
    title: null,
    location: null,
  });

  const lookups = useSelector((state) => state.auth.lookups);
  const addonLocations = lookups.addonLocations;

  const addonLocationsAr = addonLocations?.map((item) => ({
    value: item.id,
    label: item.title.ar,
  }));

  const addonLocationsEn = addonLocations?.map((item) => ({
    value: item.id,
    label: item.title.en,
  }));

  useEffect(() => {
    getItemHandler();
  }, []);

  const getItemHandler = async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');
    axios
      .get(`${Config.BASE_URL}Admin-Addon/addon/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            title: response.data.data?.title,
            location: response.data.data?.location,
          });
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  const _onEditItemHandeller = async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');
    let dataToSend = {
      addonId: id,
    };
    if (formData.title) {
      dataToSend.title = formData.title;
    }
    if (formData.location) {
      dataToSend.location = formData.location;
    }

    axios
      .put(`${Config.BASE_URL}Admin-Addon/editAddon`, dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          getItemHandler();
          showToast(translate('updatedSuccessfully'), 'success');
          const lookupsResponse = await LookupsAPI.getLookups();
          if (lookupsResponse.status === 200) {
            dispatch(setLookupsData(lookupsResponse.data.data));
          }
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  const _onSelectAddonLocationIdHandeller = (e) => {
    setFormData({ ...formData, location: e.value });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('enTitle')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData?.title?.en}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { en: e.target.value, ar: formData.title.ar },
                    })
                  }
                  name="enTitle"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('arTitle')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData?.title?.ar}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { ar: e.target.value, en: formData.title.en },
                    })
                  }
                  name="arTitle"
                  component="input"
                  type="text"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('displayLocation')}
              </span>
              <div className="form__form-group-field">
                <Select
                  name="displayLocationId"
                  className="react-select"
                  placeholder={translate('selectDisplayLocation')}
                  options={isRTL ? addonLocationsAr : addonLocationsEn}
                  onChange={(e) => _onSelectAddonLocationIdHandeller(e)}
                  value={
                    isRTL
                      ? addonLocationsAr?.find(
                          (item) => item.value === formData.location
                        )
                      : addonLocationsEn?.find(
                          (item) => item.value === formData.location
                        )
                  }
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.title ||
                  !formData?.title?.ar ||
                  !formData?.title?.en
                }
                type="submit"
                onClick={_onEditItemHandeller}
              >
                {translate('submit')}
              </Button>
              {/*<Button type="button" onClick={reset}>
                Cancel
              </Button>*/}
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
