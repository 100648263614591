import React, { useState } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone-uploader';

import { useLanguage } from '../../i18n/LanguageContext';
import { showToast } from '../../utils/toast';
import * as Config from '../../config';
import Loader from '../Loader';

const ImageUploader = ({ onImageUpload, singleUpload = false }) => {
  const { translate } = useLanguage();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const getUploadParams = () => {
    return { url: 'https://httpbin.org/post' };
  };

  const handleUploadImages = async (files, allFiles) => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((fileWrapper) => {
      formData.append('files', fileWrapper.file);
    });

    try {
      const response = await axios.post(
        `${Config.UPLOADS_URL}uploadMultiple`,
        formData
      );

      if (response.status === 200) {
        showToast(translate('success'), 'success');
        const uploadedFileNames = response.data.data.files;
        const newUploadedFiles = files.map((fileWrapper, index) => ({
          ...fileWrapper,
          fileName: uploadedFileNames[index],
        }));

        // If singleUpload is true, replace the uploaded files, otherwise append to them
        setUploadedFiles((prevFiles) =>
          singleUpload ? newUploadedFiles : [...prevFiles, ...newUploadedFiles]
        );

        onImageUpload(uploadedFileNames);

        // Disable the uploader if singleUpload is true
        if (singleUpload) {
          setDisabled(true);
        }
      } else {
        showToast(response.data.message || translate('error'), 'error');
      }
    } catch (error) {
      if (!error.response) {
        showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
      } else {
        showToast(error.response.data.message || String(error), 'error');
      }
    }

    setLoading(false);
    allFiles.forEach((f) => f.remove());
  };

  const handleRemoveImage = (index) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);
      onImageUpload(updatedFiles.map((file) => file.fileName));
      return updatedFiles;
    });
    setDisabled(false); // Enable the uploader again if singleUpload is true
  };

  return (
    <div>
      <Dropzone
        getUploadParams={getUploadParams}
        onSubmit={handleUploadImages}
        accept='image/*,application/pdf'
        maxFiles={singleUpload ? 1 : undefined} // Restrict to 1 file if singleUpload is true
        multiple={!singleUpload} // Disable multiple file upload if singleUpload is true
        inputContent={translate('imageUploaderMessage')}
        classNames={{
          dropzone: 'custom-dropzone',
          inputLabel: 'custom-input-label',
          preview: 'custom-preview',
          submitButton: 'custom-submit-button',
        }}
        disabled={disabled} // Disable the uploader if needed
      />
      {loading && <Loader />}
      <div className='uploaded-files-preview'>
        {uploadedFiles.map((fileWrapper, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              display: 'inline-block',
              margin: '10px',
            }}
          >
            <img
              src={URL.createObjectURL(fileWrapper.file)}
              alt={fileWrapper.meta.name}
              style={{ width: '100px', height: '100px' }}
            />
            <button
              onClick={() => handleRemoveImage(index)}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                backgroundColor: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
