import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import { UPLOADS_URL } from '../../../../config';
import { useLanguage } from '../../../../i18n/LanguageContext';
import ImageUploader from '../../../../containers/ImageUploader';
import { ServiceAPI } from '../../../../apis';
import useErrorHandling from '../../../../utils/error';
import { showToast } from '../../../../utils/toast';

const HorizontalForm = ({ handleSubmit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const { isRTL, translate, language } = useLanguage();

  const lookups = useSelector((state) => state.auth.lookups);

  const serviceCategories = lookups.serviceCategories;

  const serviceCategoryAr = serviceCategories?.map((item) => ({
    value: item._id,
    label: item.title.ar,
  }));

  const serviceCategoryEn = serviceCategories?.map((item) => ({
    value: item._id,
    label: item.title.en,
  }));

  const [serviceCategoryDetails, setServiceCategoryDetails] = useState({});
  const [formData, setFormData] = useState({
    companyName: {
      en: '',
      ar: '',
    },
    companyLocation: '',
    companyPhoneNumber: '',
    serviceCategoryId: serviceCategoryEn?.[0]?.value,
    description: {
      en: '',
      ar: '',
    },
    adminPersonalNotes: '',
    mainImage: '',
    images: [],
  });

  const [serviceFileData, setServiceFileData] = useState({
    title: '',
    fileName: '',
    serviceId: id,
  });

  const handleUploadedMainImage = (imageName) => {
    setFormData({ ...formData, mainImage: imageName[0] });
  };

  const handleUploadedImages = (imageNames) => {
    setFormData({ ...formData, images: imageNames });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceAPI.getServiceById(id, language);
        setServiceCategoryDetails(response.data.data.serviceCategoryDetails);
        setFormData({ ...response.data.data });
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    fetchData();
  }, [id, language]);

  const _onSelectServiceCategoryIdHandeller = (e) => {
    setFormData({ ...formData, serviceCategoryId: e.value });
    setServiceCategoryDetails({
      title: { en: e.label, ar: e.label },
      value: e.value,
    });
  };

  const _onEditServiceHandeller = async () => {
    const dataToSend = {
      companyName: {
        en: formData.companyName.en,
        ar: formData.companyName.ar,
      },
      companyLocation: formData.companyLocation,
      companyPhoneNumber: formData.companyPhoneNumber,
      serviceCategoryId: formData.serviceCategoryId,
      description: {
        en: formData.description.en,
        ar: formData.description.ar,
      },
      adminPersonalNotes: formData.adminPersonalNotes,
      mainImage: formData.mainImage,
      images: formData.images,
    };

    try {
      let serviceFileResponse;
      if (serviceFileData.title && serviceFileData.fileName) {
        serviceFileResponse = await ServiceAPI.addServiceFile(serviceFileData);
      } else {
        serviceFileResponse = { status: 200 };
      }

      const response = await ServiceAPI.editService(id, dataToSend);

      if (response.status === 200 && serviceFileResponse.status === 200) {
        showToast(translate('updatedSuccessfully'), 'success');
        navigate(`/services/list`);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleRemoveMainImage = () => {
    setFormData({ ...formData, mainImage: '' });
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = formData.images.filter(
      (_, index) => index !== indexToRemove
    );
    setFormData({ ...formData, images: updatedImages });
  };

  const handleUploadedFiles = (fileNames) => {
    setServiceFileData({ ...serviceFileData, fileName: fileNames[0] });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('arabicCompanyName')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyName.ar}
                  name={'companyName'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyName')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyName: {
                        ...formData.companyName,
                        ar: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('englishCompanyName')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyName.en}
                  name={'companyName'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyName')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyName: {
                        ...formData.companyName,
                        en: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('companyLocation')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyLocation}
                  name={'companyLocation'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyLocation')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyLocation: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('companyPhoneNumber')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyPhoneNumber}
                  name={'companyPhoneNumber'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyPhoneNumber')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyPhoneNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('serviceCategoryId')}
              </span>
              <div className="form__form-group-field">
                <Select
                  name="serviceCategoryId"
                  className="react-select"
                  placeholder={translate('selectServiceCategoryType')}
                  options={isRTL ? serviceCategoryAr : serviceCategoryEn}
                  value={{
                    value: formData.serviceCategoryId,
                    label: isRTL
                      ? serviceCategoryDetails?.title?.ar
                      : serviceCategoryDetails?.title?.en,
                  }}
                  onChange={(e) => _onSelectServiceCategoryIdHandeller(e)}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('arabicDescription')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.description?.ar}
                  name={'description'}
                  component="input"
                  type="text"
                  placeholder={translate('enterDescription')}
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      description: {
                        ...formData.description,
                        ar: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('englishDescription')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.description?.en}
                  name={'description'}
                  component="input"
                  type="text"
                  placeholder={translate('enterDescription')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      description: {
                        ...formData.description,
                        en: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('adminPersonalNotes')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.adminPersonalNotes}
                  name={'description'}
                  component="input"
                  type="text"
                  placeholder={translate('enterDescription')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      adminPersonalNotes: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <h5
              className="bold-text mb-4"
              style={{
                marginTop: '50px',
              }}
            >
              {translate('serviceMainImage')}
            </h5>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {formData.mainImage?.length > 0 ? (
                <>
                  <img
                    src={`${UPLOADS_URL}uploads/${formData.mainImage}`}
                    alt=""
                    style={{ width: '40%', height: 'auto' }}
                  />
                  <button
                    onClick={() => handleRemoveMainImage()}
                    style={{
                      position: 'relative',
                      bottom: '10px',
                      left: '10px',
                      backgroundColor: 'red',
                      color: 'white',
                      border: 'none',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    &times;
                  </button>
                </>
              ) : (
                `${translate('noImages')}`
              )}
            </div>

            <h5
              className="bold-text mb-4"
              style={{
                marginTop: '30px',
              }}
            >
              {translate('serviceImages')}
            </h5>

            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridGap: '20px',
                alignItems: 'center',
              }}
            >
              {formData?.images?.length > 0
                ? formData?.images?.map((image, index) => (
                    <div
                      style={{ position: 'relative', width: '100%' }}
                      key={index}
                    >
                      <img
                        src={`${UPLOADS_URL}uploads/${image}`}
                        alt=""
                        style={{ width: '100%', height: 'auto' }}
                      />
                      <button
                        onClick={() => handleRemoveImage(index)}
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          left: '-10px',
                          backgroundColor: 'red',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ))
                : `${translate('noImages')}`}
            </div>

            <h5
              className="bold-text mb-4"
              style={{
                marginTop: '50px',
              }}
            >
              {translate('uploadServiceMainImage')}
            </h5>

            <div className="form__form-group">
              <div className="form__form-group-field">
                <ImageUploader
                  onImageUpload={handleUploadedMainImage}
                  singleUpload={true}
                />
              </div>
            </div>

            <h5 className="bold-text mb-4">
              {translate('uploadServiceImages')}
            </h5>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <ImageUploader onImageUpload={handleUploadedImages} />
              </div>
            </div>

            <h4 className="text-justify row">{translate('addServiceFile')}</h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('serviceFileTitle')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={serviceFileData.title}
                    name="title"
                    component="input"
                    type="text"
                    placeholder={translate('enterTitle')}
                    onChange={(e) =>
                      setServiceFileData({
                        ...serviceFileData,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <h5 className="bold-text mb-4">
                {translate('uploadServiceFiles')}
              </h5>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <ImageUploader
                    onImageUpload={handleUploadedFiles}
                    singleUpload={true}
                  />
                </div>
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={!formData.companyName}
                onClick={_onEditServiceHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
