import React from 'react';
import App from './screens/App/App';
import ReactDOM from 'react-dom/client';
import { LangaugeProvider } from './i18n/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LangaugeProvider>
    <App />
  </LangaugeProvider>
);
