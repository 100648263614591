import React, { useState } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ServiceAPI } from '../../../../apis';
import { showToast } from '../../../../utils/toast';
import { useLanguage } from '../../../../i18n/LanguageContext';
import ImageUploader from '../../../../containers/ImageUploader';

const HorizontalForm = ({ handleSubmit, reset }) => {
  const navigate = useNavigate();
  const { isRTL, translate } = useLanguage();
  const lookups = useSelector((state) => state.auth.lookups);

  const serviceCategories = lookups.serviceCategories;

  const serviceCategoryAr = serviceCategories?.map((item) => ({
    value: item._id,
    label: item.title.ar,
  }));

  const serviceCategoryEn = serviceCategories?.map((item) => ({
    value: item._id,
    label: item.title.en,
  }));

  const [formData, setFormData] = useState({
    companyName: {
      en: '',
      ar: '',
    },
    companyLocation: '',
    companyPhoneNumber: '',
    serviceCategoryId: undefined,
    description: {
      en: '',
      ar: '',
    },
    adminPersonalNotes: '',
    mainImage: '',
    images: [],
  });

  const handleUploadedMainImage = (imageName) => {
    setFormData({ ...formData, mainImage: imageName[0] });
  };

  const handleUploadedImages = (imageNames) => {
    setFormData({ ...formData, images: imageNames });
  };

  const _onSelectServiceCategoryIdHandeller = (e) => {
    setFormData({ ...formData, serviceCategoryId: e.value });
  };

  const _onAddServiceHandeller = async () => {
    const dataToSend = { ...formData };

    const response = await ServiceAPI.addNewService(dataToSend);
    if (response.status === 200) {
      navigate(`/services/list`);
      showToast(translate('addedSuccessfully'), 'success');
    } else {
      showToast(response.message || translate('error'), 'error');
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('arabicCompanyName')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyName.ar}
                  name={'companyName'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyName')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyName: {
                        ...formData.companyName,
                        ar: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('englishCompanyName')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyName.en}
                  name={'companyName'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyName')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyName: {
                        ...formData.companyName,
                        en: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('companyLocation')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyLocation}
                  name={'companyLocation'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyLocation')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyLocation: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('companyPhoneNumber')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.companyPhoneNumber}
                  name={'companyPhoneNumber'}
                  component="input"
                  type="text"
                  placeholder={translate('enterCompanyPhoneNumber')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyPhoneNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('serviceCategoryId')}
              </span>
              <div className="form__form-group-field">
                <Select
                  name="serviceCategoryId"
                  className="react-select"
                  placeholder={translate('selectServiceCategoryType')}
                  options={isRTL ? serviceCategoryAr : serviceCategoryEn}
                  onChange={(e) => _onSelectServiceCategoryIdHandeller(e)}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('arabicDescription')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.description?.ar}
                  name={'description'}
                  component="input"
                  type="text"
                  placeholder={translate('enterDescription')}
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      description: {
                        ...formData.description,
                        ar: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('englishDescription')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.description?.en}
                  name={'description'}
                  component="input"
                  type="text"
                  placeholder={translate('enterDescription')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      description: {
                        ...formData.description,
                        en: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('adminPersonalNotes')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.adminPersonalNotes}
                  name={'description'}
                  component="input"
                  type="text"
                  placeholder={translate('enterDescription')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      adminPersonalNotes: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <h5 className="bold-text mb-4">
              {translate('uploadServiceMainImage')}
            </h5>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <ImageUploader
                  onImageUpload={handleUploadedMainImage}
                  singleUpload={true}
                />
              </div>
            </div>

            <h5 className="bold-text mb-4">
              {translate('uploadServiceImages')}
            </h5>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <ImageUploader onImageUpload={handleUploadedImages} />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={!formData.companyName || !formData.companyPhoneNumber}
                onClick={_onAddServiceHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
