import axios from 'axios';
import { BASE_URL } from './config';

export const getLookups = async () => {
  const url = `${BASE_URL}Common/lookups`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
