import axios from 'axios';
import { BASE_URL } from './config';

export const sendNotification = async (dataToSend) => {
  const url = `${BASE_URL}Admin-Notification/sendNotification`;

  try {
    const response = await axios.post(url, dataToSend);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendBatchNotification = async (dataToSend) => {
  const url = `${BASE_URL}Admin-Notification/sendBatchNotification`;

  try {
    const response = await axios.post(url, dataToSend);
    return response;
  } catch (error) {
    throw error;
  }
};

export const listAllNotifications = async (searchField, page, perPage) => {
  const searchParam = searchField ? `&search=${searchField}` : '';
  const url = `${BASE_URL}Admin-Notification/listNotifications?page=${page}&perPage=${perPage}${searchParam}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
