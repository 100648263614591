import React from 'react';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { REQUEST_STATUS } from '../../../../utils/allDropdownData';
import * as Config from '../../../../config';
import { sessionExpire } from '../../../../redux/actions/authAction';

import axios from 'axios';
import { showToast } from '../../../../utils/toast';

const RequestItem = ({ request, onSellRent }) => {
  const dispatch = useDispatch();
  const { translate, language } = useLanguage();

  const handleRent = async () => {
    const dataToSend = {
      requestStatusId: REQUEST_STATUS.RENTED,
      _id: request._id,
    };

    axios
      .put(`${Config.BASE_URL}Admin-Estate/updateEstate`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          onSellRent();
          showToast(translate('updatedSuccessfully'), 'success');
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  const handleSell = async () => {
    const dataToSend = {
      requestStatusId: REQUEST_STATUS.SOLD,
      _id: request._id,
    };

    axios
      .put(`${Config.BASE_URL}Admin-Estate/updateEstate`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          onSellRent();
          showToast(translate('updatedSuccessfully'), 'success');
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  return (
    <>
      <div className="property-requests-table">
        <Link to={`/property/3/${request?.requestStatusId}/${request._id}`}>
          <div className="d-flex">
            <img
              src={
                request?.mainImage
                  ? `${Config.UPLOADS_URL}uploads/${request?.mainImage}`
                  : require('../../../../assets/images/icons/avatar.jpg')
              }
              alt="profile"
              style={{
                borderRadius: '10%',
                margin: '10px',
                width: '70px',
                height: '70px',
                marginLeft: '30px',
              }}
            />
            <Table responsive style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>
                    {language === 'en'
                      ? request?.userDetails?.nameEn
                      : request?.userDetails?.nameAr}
                  </th>
                  <th>{translate('governorate')}</th>
                  <th>{translate('area')}</th>
                  <th>{translate('block')}</th>
                  <th>{translate('streetTitle')}</th>
                  <th>{translate('homeTitle')}</th>
                  <th>{translate('address')}</th>
                  <th>{translate('referenceTitle')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{request?.userDetails?.phoneNumber}</p>
                  </td>
                  <td>{request?.governorateDetails?.title?.[language]}</td>
                  <td>{request?.areaDetails?.title?.[language]}</td>
                  <td>{request?.block}</td>
                  <td>{request?.streetNo}</td>
                  <td>{request?.homeNo}</td>
                  <td>{request?.location}</td>
                  <td>{request?.referenceNo}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Link>
        {/* <div className='actions'>
          <Button
            onClick={() => {}}
            color='success'
            style={{ padding: '5px 10px', background: 'green' }}
          >
            {translate('edit')}
          </Button>
          {request?.requestTypeId === REQUEST_TYPE.RENT && (
            <Button
              onClick={() => handleRent()}
              color='success'
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                width: '70px',
              }}
            >
              {translate('rented')}
            </Button>
          )}
          {request?.requestTypeId === REQUEST_TYPE.SELL && (
            <Button
              onClick={handleSell}
              color='success'
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                width: '70px',
              }}
            >
              {translate('soldOut')}
            </Button>
          )}
        </div> */}
      </div>
      <div
        className="line"
        style={{
          width: '97%',
          border: '1px solid rgba(96, 96, 96, 0.1)',
          margin: '10px auto',
        }}
      />
    </>
  );
};

export default RequestItem;
