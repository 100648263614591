import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import HorizontalForm from './components/HorizontalForm';
import { showResults } from '../../../utils/functions';
import { useLanguage } from '../../../i18n/LanguageContext';
import { useNavigate, useParams } from 'react-router-dom';

const AddForm = () => {
  const { translate } = useLanguage();
  const { actionId, status, propertyId } = useParams();
  const navigate = useNavigate();

  const ACTIONS_IDS = {
    ADD: 1,
    EDIT: 2,
    VIEW: 3,
    ACTIVATE: 4,
  };
  const handleActivate = () => {
    // navigate(`/activateRequest/${id}`);
  };
  return (
    <Container>
      <Row>
        <Col md={12} className="d-flex justify-content-between px-5">
          <h3 className="page-title">
            {actionId == 1
              ? translate('addProperty')
              : actionId == 4
              ? translate('activateRequest')
              : translate('propertyDetails')}
          </h3>
          <div>
            {(status === 'pending' || status === 'rejected') && (
              <Button
                color="success"
                style={{ padding: '5px 10px', background: 'green' }}
              >
                {translate('accept')}
              </Button>
            )}
            {(status === 'pending' || status === 'accepted') && (
              <Button
                color="danger"
                style={{ marginRight: '10px', padding: '5px 10px' }}
              >
                {translate('reject')}
              </Button>
            )}
            {status === 'accepted' && (
              <Button
                onClick={handleActivate}
                color="success"
                style={{ marginRight: '10px', padding: '5px 10px' }}
              >
                {translate('activate')}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <HorizontalForm
          onSubmit={showResults}
          actionId={actionId}
          status={status}
          propertyId={propertyId}
          ACTIONS_IDS={ACTIONS_IDS}
        />
      </Row>
    </Container>
  );
};

AddForm.propTypes = {
  t: PropTypes.func,
};

export default AddForm;
