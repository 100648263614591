import React from 'react';
import { Table, Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';

import { ServiceAPI } from '../../apis';
import { UPLOADS_URL } from '../../config/index';
import { useLanguage } from '../../i18n/LanguageContext';

const ServiceItem = ({ request, onDelete }) => {
  const navigate = useNavigate();
  const { translate, isRTL } = useLanguage();
  console.log(request);
  const {
    _id,
    companyName,
    companyLocation,
    description,
    adminPersonalNotes,
    serviceCategoryDetails,
    mainImage,
  } = request;

  return (
    <>
      <div className="property-requests-table">
        <Link to={`/viewService/${_id}`}>
          <div className="inner-container">
            <img
              src={
                mainImage
                  ? `${UPLOADS_URL}uploads/${mainImage}`
                  : require('../../assets/images/icons/empty-image.png')
              }
              alt="property"
              style={{
                borderRadius: '10%',
                margin: '10px',
                width: '70px',
                height: '70px',
                marginLeft: '30px',
              }}
            />
            <Table responsive style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>{translate('companyName')}</th>
                  <th>{translate('companyLocation')}</th>
                  <th>{translate('serviceCategoryId')}</th>
                  <th>{translate('description')}</th>
                  <th>{translate('adminNotes')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{isRTL ? companyName?.en : companyName?.ar}</td>
                  <td>{companyLocation}</td>
                  <td>
                    {isRTL
                      ? serviceCategoryDetails?.title?.ar
                      : serviceCategoryDetails?.title?.en}
                  </td>
                  <td>{isRTL ? description.ar : description.en}</td>
                  <td>
                    <p>{adminPersonalNotes}</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Link>
        <div className="actions">
          <Button
            color="success"
            style={{ marginRight: '10px', padding: '5px 10px' }}
            onClick={() => {
              navigate(`/services/edit/${_id}`);
            }}
          >
            {translate('edit')}
          </Button>
          <Button
            color="danger"
            style={{ marginRight: '10px', padding: '5px 10px' }}
            onClick={() => {
              onDelete(_id);
            }}
          >
            {translate('delete')}
          </Button>
        </div>
      </div>
      <div
        className="line"
        style={{
          width: '97%',
          border: '1px solid rgba(96, 96, 96, 0.1)',
          margin: '10px auto',
        }}
      />
    </>
  );
};

export default ServiceItem;
