import axios from 'axios';
import { BASE_URL } from './config';

export const addUser = async (userData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}Admin-Users/addUser`,
      userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const editUser = async (dataToSend) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}Admin-Users/editUser`,
      dataToSend
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}Admin-Users/deleteUser/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserById = async (id, language) => {
  try {
    const url = `${BASE_URL}Admin-Users/${id}`;

    const response = await axios.get(url, {
      headers: {
        'Accept-Language': language,
      },
    });
    return response;
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

export const listUsers = async (searchField, page, perPage) => {
  const searchParam = searchField ? `&search=${searchField}` : '';
  const url = `${BASE_URL}Admin-Users/listUsers?page=${page}&perPage=${perPage}${searchParam}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const activateDeactivateUser = async (id) => {
  const url = `${BASE_URL}Admin-Users/activeInnactiveUser/${id}`;
  try {
    const response = await axios.patch(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCompanyUsers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}Admin-Users/listCompanyUsers`);
    return response;
  } catch (error) {
    throw error;
  }
};
