// src/utils/toast.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const showToast = (message, type = 'default') => {
  switch (type) {
    case 'success':
      toast.success(message, ToastConfig);
      break;
    case 'error':
      toast.error(message, ToastConfig);
      break;
    case 'info':
      toast.info(message, ToastConfig);
      break;
    case 'warn':
      toast.warn(message, ToastConfig);
      break;
    default:
      toast(message, ToastConfig);
      break;
  }
};

export const showToastPromise = (promise, messages) => {
  toast.promise(
    promise,
    {
      pending: messages.pending,
      success: messages.success,
      error: messages.error,
    },
    ToastConfig
  );
};
