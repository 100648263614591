import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TotalActivated from './components/TotalActivated';
import TotalPending from './components/TotalPending';
import TotalRejected from './components/TotalRejected';
import { useLanguage } from '../../../i18n/LanguageContext';
import { showToast } from '../../../utils/toast';
import TotalVisit from './components/TotalVisit';
import TotalSold from './components/TotalSold';
import TotalRented from './components/TotalRented';
import { DashboardAPI } from '../../../apis';
import useErrorHandling from '../../../utils/error';

const MobileAppDashboard = ({ t }) => {
  const { translate } = useLanguage();
  const handleError = useErrorHandling();
  const [dashboardData, setDashboardData] = useState({
    pendingCount: '-',
    visitCount: '-',
    activatedCount: '-',
    rentedCount: '-',
    soldCount: '-',
    rejectedCount: '-',
  });

  useEffect(() => {
    getDashBoardData();
  }, []);

  const getDashBoardData = async () => {
    try {
      const response = await DashboardAPI.getDashboardData();

      if (response.status === 200) {
        const data = response.data.data;
        setDashboardData({
          pendingCount: data.pendingCount,
          visitCount: data.visitCount,
          activatedCount: data.activatedCount,
          rentedCount: data.rentedCount,
          soldCount: data.soldCount,
          rejectedCount: data.rejectedCount,
        });
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h1 className="page-title">{translate('statistics')}</h1>
        </Col>
      </Row>
      <Row>
        <TotalPending count={dashboardData.pendingCount} />
        <TotalVisit count={dashboardData.visitCount} />
        <TotalActivated count={dashboardData.activatedCount} />
        <TotalRejected count={dashboardData.rejectedCount} />
        <TotalSold count={dashboardData.soldCount} />
        <TotalRented count={dashboardData.rentedCount} />
      </Row>
    </Container>
  );
};

MobileAppDashboard.propTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  rtl: state.rtl,
});

export default connect(mapStateToProps)(
  withTranslation('common')(MobileAppDashboard)
);
