import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';

import '../../../../../scss/Checkbox.css';
import * as Config from '../../../../../config';
import { showToast } from '../../../../../utils/toast';
import { useLanguage } from '../../../../../i18n/LanguageContext';
import { COMMON_STATUS } from '../../../../../utils/allDropdownData';
import useErrorHandling from '../../../../../utils/error';
import { FilterKeyAPI, LookupsAPI } from '../../../../../apis';
import FilterKeys from '../../../../../shared/components/FilterKeys';
import { setLookupsData } from '../../../../../redux/actions/authAction';
import { useDispatch } from 'react-redux';

const HorizontalForm = ({ handleSubmit }) => {
  const handleError = useErrorHandling();
  const { translate, language } = useLanguage();
  const dispatch = useDispatch();

  const [addons, setAddons] = useState([]);
  const [filterKeys, setFilterKeys] = useState([]);
  const [firstLaunch, setFirstLaunch] = useState(true);
  const [formData, setFormData] = useState({
    title: { en: '', ar: '' },
    addonIds: [],
    rentFilterKeyIds: [],
    sellFilterKeyIds: [],
    adminFilterKeyIds: [],
    rentStatus: COMMON_STATUS.ACTIVE,
    sellStatus: COMMON_STATUS.ACTIVE,
  });

  const getItemHandler = useCallback(async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');

    axios
      .get(`${Config.BASE_URL}Admin-PropertyType/propertyType/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const list = [];

          if (response.data.data.addonIds?.length > 0 && addons?.length > 0) {
            addons.forEach((element) => {
              response.data.data.addonIds.forEach((item) => {
                if (element.value === item)
                  list.push({
                    label: element?.label,
                    value: element?.value,
                  });
              });
            });
          }
          setFormData({
            title: response.data.data?.title,
            rentFilterKeyIds: response.data.data?.rentFilterKeyIds,
            sellFilterKeyIds: response.data.data?.sellFilterKeyIds,
            adminFilterKeyIds: response.data.data?.adminFilterKeyIds,
            addonIds: list,
            rentStatus: response.data.data?.rentStatus,
            sellStatus: response.data.data?.sellStatus,
          });
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [addons, handleError, translate]);

  const getAllAddons = async () => {
    axios
      .get(
        `${Config.BASE_URL}Admin-Addon/listAddons?status=${COMMON_STATUS.ACTIVE}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.listing) {
            const list = [];
            response.data.data.listing?.forEach((element) => {
              list.push({
                label: element?.title?.[language === 'en' ? 'en' : 'ar'],
                value: element?._id,
              });
            });
            setAddons(list);
          }
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getAllFilterKeys = async () => {
    try {
      const response = await FilterKeyAPI.getAllFilterKeys(
        COMMON_STATUS.ACTIVE
      );
      if (response.status === 200) {
        setFilterKeys(response.data.data.listing);
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAllAddons();
    getAllFilterKeys();
  }, []);

  useEffect(() => {
    if (addons.length > 0 && filterKeys.length > 0 && firstLaunch) {
      setFirstLaunch(false);
      getItemHandler();
    }
  }, [addons, filterKeys, firstLaunch, getItemHandler]);

  const _onEditItemHandeller = async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');
    const addonValues =
      formData.addonIds?.length > 0
        ? formData.addonIds.map((addon) => addon.value)
        : [];

    const dataToSend = {
      propertyTypeId: id,
      title: formData.title,
      rentStatus: formData.rentStatus,
      sellStatus: formData.sellStatus,
      rentFilterKeyIds: formData.rentFilterKeyIds,
      sellFilterKeyIds: formData.sellFilterKeyIds,
      adminFilterKeyIds: formData.adminFilterKeyIds,
      addonIds: addonValues,
    };

    axios
      .put(`${Config.BASE_URL}Admin-PropertyType/editPropertyType`, dataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          getItemHandler();
          showToast(translate('updatedSuccessfully'), 'success');
          const lookupsResponse = await LookupsAPI.getLookups();
          if (lookupsResponse.status === 200) {
            dispatch(setLookupsData(lookupsResponse.data.data));
          }
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleCheckboxChange = (key, _id) => {
    const filterKeyIds = formData[key] || [];
    const isAlreadyIncluded = filterKeyIds.includes(_id);

    setFormData({
      ...formData,
      [key]: isAlreadyIncluded
        ? filterKeyIds.filter((id) => id !== _id)
        : [...filterKeyIds, _id],
    });
  };

  const toggleStatus = (key) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]:
        prevData[key] === COMMON_STATUS.ACTIVE
          ? COMMON_STATUS.INACTIVE
          : COMMON_STATUS.ACTIVE,
    }));
  };

  const selectAddons = (e) => {
    const copySelected = [...e];
    setFormData((prevState) => ({
      ...prevState,
      addonIds: copySelected,
    }));
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form
            className="form form--horizontal"
            onSubmit={handleSubmit(_onEditItemHandeller)}
          >
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('enTitle')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData?.title?.en}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { en: e.target.value, ar: formData.title.ar },
                    })
                  }
                  name="enTitle"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('arTitle')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData?.title?.ar}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { ar: e.target.value, en: formData.title.en },
                    })
                  }
                  name="arTitle"
                  component="input"
                  type="text"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('addons')}
              </span>
              <div className="form__form-group-field">
                <Select
                  isMulti
                  name="user"
                  menuPortalTarget={document.querySelector('body')}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={addons}
                  onChange={(e) => selectAddons(e)}
                  value={formData.addonIds}
                />
              </div>
            </div>

            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="flex">
                  <FilterKeys
                    title="forRent"
                    status={formData.rentStatus}
                    filterKeyIds={formData.rentFilterKeyIds}
                    filterKeyType="rentFilterKeyIds"
                    handleCheckboxChange={handleCheckboxChange}
                    changeStatus={() => toggleStatus('rentStatus')}
                  />
                  <FilterKeys
                    title="forSale"
                    status={formData.sellStatus}
                    filterKeyIds={formData.sellFilterKeyIds}
                    filterKeyType="sellFilterKeyIds"
                    handleCheckboxChange={handleCheckboxChange}
                    changeStatus={() => toggleStatus('sellStatus')}
                  />
                  <FilterKeys
                    title="forAdmins"
                    filterKeyIds={formData.adminFilterKeyIds}
                    filterKeyType="adminFilterKeyIds"
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.title ||
                  !formData?.title?.ar ||
                  !formData?.title?.en
                }
                type="submit"
              >
                {translate('submit')}
              </Button>
              {/*<Button type="button" onClick={reset}>
                Cancel
              </Button>*/}
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
