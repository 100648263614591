import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLanguage } from '../../../i18n/LanguageContext';

const SidebarCategory = ({ title, icon, isNew, children }) => {
  const { isRTL } = useLanguage();
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse((prevCollapse) => !prevCollapse);
  };

  const categoryClass = classNames({
    'sidebar__category-wrap': true,
    'sidebar__category-wrap--open': collapse,
  });

  return (
    <div className={categoryClass}>
      <button
        type='button'
        className={`${
          isRTL ? 'sidebar__link__rtl' : 'sidebar__link__ltr'
        } sidebar__category`}
        onClick={toggle}
      >
        {icon ? (
          <span
            className={`${
              isRTL ? 'sidebar__link-icon__rtl' : 'sidebar__link-icon__ltr'
            } lnr lnr-${icon}`}
          />
        ) : (
          ''
        )}
        <p className='sidebar__link-title'>
          {title}
          {isNew && <span className='sidebar__category-new' />}
        </p>
        <span
          className={`${
            isRTL
              ? 'sidebar__category-icon__rtl'
              : 'sidebar__category-icon__ltr'
          } lnr lnr-chevron-right`}
        />
      </button>
      <Collapse
        isOpen={collapse}
        className={
          isRTL ? 'sidebar__submenu-wrap__rtl' : 'sidebar__submenu-wrap__ltr'
        }
      >
        <ul
          className={isRTL ? 'sidebar__submenu__rtl' : 'sidebar__submenu__ltr'}
        >
          <div>{children}</div>
        </ul>
      </Collapse>
    </div>
  );
};

SidebarCategory.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isNew: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

SidebarCategory.defaultProps = {
  icon: '',
  isNew: false,
};

export default SidebarCategory;
