import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import HorizontalForm from './components';
import { useLanguage } from '../../../i18n/LanguageContext';
import { useParams } from 'react-router-dom';
import { ServiceAPI } from '../../../apis';

const ViewForm = () => {
  const { translate, language } = useLanguage();
  const { id } = useParams();
  const [service, setService] = useState({
    companyName: '',
    companyLocation: '',
    companyPhoneNumber: '',
    description: {
      en: '',
      ar: '',
    },
    adminPersonalNotes: '',
    serviceCategoryDetails: {
      title: {
        en: '',
        ar: '',
      },
    },
    status: '',
    files: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceAPI.getServiceById(id, language);
        setService(response.data.data);

        const filesResponse = await ServiceAPI.listServiceFiles(id);
        setService((prev) => ({ ...prev, files: filesResponse.data.data }));
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    fetchData();
  }, [id, language]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className='page-title'>{translate('viewServiceDetails')}</h3>
        </Col>
      </Row>
      <Row>
        <HorizontalForm response={service} />
      </Row>
    </Container>
  );
};

ViewForm.propTypes = {
  t: PropTypes.func,
};

export default ViewForm;
