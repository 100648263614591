import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from './components/DataTable';
import { useLanguage } from '../../../../i18n/LanguageContext';

const ListTable = ({ t }) => {
  const { translate } = useLanguage();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className='page-title'>{translate('filterKeyList')}</h3>
        </Col>
      </Row>
      <Row>
        <Table />
      </Row>
    </Container>
  );
};

ListTable.propTypes = {
  t: PropTypes.func,
};

export default ListTable;
