import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import HorizontalForm from './components';
import { useLanguage } from '../../../i18n/LanguageContext';

const ViewForm = () => {
  const { translate, language } = useLanguage();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{translate('viewMarketDetails')}</h3>
        </Col>
      </Row>
      <Row>
        <HorizontalForm />
      </Row>
    </Container>
  );
};

ViewForm.propTypes = {
  t: PropTypes.func,
};

export default ViewForm;
