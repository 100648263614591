/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../i18n/LanguageContext';
import RequestItem from '../../../shared/components/RequestItem';
import Pagination from 'react-js-pagination';
import axios from 'axios';
import { ResponsiveContainer } from 'recharts';

import * as Config from '../../../config/index';
import Panel from '../../../shared/components/Panel';
import Search from '../../../shared/components/Search';
import Loader from '../../../containers/Loader/index';
import { REQUEST_STATUS } from '../../../utils/allDropdownData';
import { showToast } from '../../../utils/toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import { EstateAPI } from '../../../apis';
import useErrorHandling from './../../../utils/error';

const RejectedRequests = () => {
  const handleError = useErrorHandling();
  const { isRTL, translate } = useLanguage();
  const [state, setState] = useState({
    id: '',
    items: [],
    pageRange: 5,
    page: 1,
    perPage: 10,
    totalItemCount: 0,
    alertApprove: false,
    searchField: '',
    isLoading: false,
  });

  useEffect(() => {
    itemsListing(state.searchField, state.page);
  }, []);

  const itemsListing = async (searchField, pageNumber) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await EstateAPI.getAllEstates({
      status: REQUEST_STATUS.REJECTED,
      searchField,
      pageNumber,
      perPage: state.perPage,
    });
    try {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          items: response.data.data.listing,
          totalItemCount: response.data.data.count,
        }));
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      handleError(error);
    }
  };

  const onApproveHandeler = async (id) => {
    setState((prevState) => ({
      ...prevState,
      alertApprove: true,
      id: id,
    }));
  };

  const onConfirmApprove = async (val) => {
    if (val) {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        alertApprove: false,
      }));
      const url = `${Config.BASE_URL}Admin-Estate/moveToVisitEstateRequest/${state.id}`;
      axios
        .patch(url)
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          if (response.status === 200) {
            itemsListing(state.searchField, state.page);
          } else {
            return response.data.message
              ? showToast(response.data.message, 'error')
              : showToast(translate('error'), 'error');
          }
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          handleError(error);
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        alertApprove: false,
      }));
    }
  };

  const handlePageChange = (pageNumber) => {
    setState((prevState) => ({ ...prevState, page: pageNumber }));
    itemsListing(state.searchField, pageNumber);
  };

  const setSearchField = (field) => {
    setState((prevState) => ({
      ...prevState,
      searchField: field,
    }));
    itemsListing(field, state.page);
  };

  return (
    <div className='card'>
      <div className='content'>
        <Panel
          xl={12}
          lg={12}
          md={12}
          xs={12}
          title=''
          subhead=''
          refreshFunc={() => itemsListing(state.searchField, state.page)}
          withAddFunc={false}
          placement={isRTL ? 'rtl' : 'ltr'}
        >
          <Search
            placeholder={translate('requestSearch')}
            searchField={state.searchField}
            setSearchField={setSearchField}
            search={() => itemsListing(state.searchField, state.page)}
          />
          <div dir={isRTL ? 'rtl' : 'ltr'}>
            {state.isLoading && <Loader />}
            <ResponsiveContainer className='dashboard__area'>
              <div className='add-property-page--content'>
                <div
                  className='requests-header d-flex justify-content-between'
                  style={{ marginBottom: '20px' }}
                >
                  <h1>{translate('rejectedRequests')}</h1>
                </div>
                {state?.items?.map((request) => {
                  return (
                    <RequestItem
                      request={request}
                      onAccept={onApproveHandeler}
                    />
                  );
                })}
              </div>
            </ResponsiveContainer>
            <div className='row'>
              <div className='col-md-12'>
                <Pagination
                  activePage={state.page}
                  itemsCountPerPage={state.perPage}
                  totalItemsCount={state.totalItemCount}
                  pageRangeDisplayed={state.pageRange}
                  onChange={(n) => handlePageChange(n)}
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <SweetAlert
        success
        showCancel
        custom
        confirmBtnText={translate('yes')}
        show={state.alertApprove}
        cancelBtnText={translate('no')}
        confirmBtnBsStyle='success'
        cancelBtnBsStyle='default'
        btnSize='sm'
        title={translate('approveAlert')}
        onConfirm={() => onConfirmApprove(true)}
        onCancel={() => onConfirmApprove(false)}
      />
    </div>
  );
};

export default RejectedRequests;
