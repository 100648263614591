import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import axios from 'axios';
import * as Config from '../../../../../config';
import { useLanguage } from '../../../../../i18n/LanguageContext';
import { showDate } from '../../../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../../../utils/toast';
import { sessionExpire } from '../../../../../redux/actions/authAction';

const HorizontalForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRTL, translate, language } = useLanguage();
  const [formData, setFormData] = useState({
    title: null,
    governorateId: '',
    createdAt: null,
    governorates: [],
  });

  useEffect(() => {
    getItemHandler();
    getGovernorates();
  }, []);

  const getItemHandler = async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');

    axios
      .get(`${Config.BASE_URL}Admin-Area/area/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            title: {
              en: response.data.data?.title?.en,
              ar: response.data.data?.title?.ar,
            },
            governorateId: response.data.data?.governorateId,
            createdAt: response.data.data?.createdAt,
          });
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  const getGovernorates = async () => {
    const url = `${Config.BASE_URL}Admin-Governorate/listGovernorate`;

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setFormData((prevState) => ({
            ...prevState,
            governorates: response.data.data.listing,
            isLoading: false,
          }));
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('enTitle')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData?.title?.en}
                  disabled
                  name="firstname"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('arTitle')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData?.title?.ar}
                  disabled
                  name="arTitle"
                  component="input"
                  type="text"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('governorate')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={
                    formData.governorates &&
                    formData.governorates.find(
                      (item) => item._id === formData?.governorateId
                    )?.title?.[language === 'en' ? 'en' : 'ar']
                  }
                  name="createdAt"
                  component="input"
                  type="text"
                  disabled
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('createdAt')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={
                    formData?.createdAt ? showDate(formData.createdAt) : ''
                  }
                  name="createdAt"
                  component="input"
                  type="text"
                  disabled
                />
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
