import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';

import { NotificationAPI, UserAPI } from '../../../../apis';
import { showToast } from '../../../../utils/toast';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { sessionExpire } from '../../../../redux/actions/authAction';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

const HorizontalForm = ({ handleSubmit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLanguage();

  const [formData, setFormData] = useState({
    title: {
      en: '',
      ar: '',
    },
    body: {
      en: '',
      ar: '',
    },
  });

  const [users, setUsers] = useState([]);
  const [sendToAllUsers, setSendToAllUsers] = useState(true);

  const addNotificationHandeller = async () => {
    try {
      let response;
      if (sendToAllUsers) {
        response = await NotificationAPI.sendBatchNotification(formData);
      } else {
        response = await NotificationAPI.sendNotification(formData);
      }
      if (response.status === 200) {
        showToast(translate('notificationSentSuccessfully'), 'success');
        navigate('/notification/list');
      } else {
        return showToast(response.message || translate('error'), 'error');
      }
    } catch (error) {
      if (!error.response) {
        return showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
        dispatch(sessionExpire());
      } else {
        return showToast(error.response.data.message || String(error), 'error');
      }
    }
  };

  const listUsers = async (searchField, page, perPage) => {
    try {
      const response = await UserAPI.listUsers(searchField, page, perPage);
      if (response.status === 200) {
        setUsers(response.data.data.listing);
      } else {
        return showToast(response.data.message || translate('error'), 'error');
      }
    } catch (error) {
      if (!error.response) {
        return showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
        dispatch(sessionExpire());
      } else {
        return showToast(error.response.data.message || String(error), 'error');
      }
    }
  };

  useEffect(() => {
    listUsers('', 1, 10);
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationTitleEN')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationTitleEN"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationTitle')}
                  value={formData.title.en}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { ...formData.title, en: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationTitleAR')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationTitleAR"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationTitle')}
                  value={formData.title.ar}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { ...formData.title, ar: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationBodyEN')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationBodyEN"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationBody')}
                  value={formData.body.en}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      body: { ...formData.body, en: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationBodyAR')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationBodyAR"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationBody')}
                  value={formData.body.ar}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      body: { ...formData.body, ar: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('sendToOneUser')}
              </span>
              <div className="form__form-group-field">
                <Select
                  name="user"
                  className="react-select"
                  placeholder={translate('selectUser')}
                  options={users.map((user) => ({
                    value: user._id,
                    label: user.nameAr,
                  }))}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      userId: e.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="form__form-group mt-5">
              <div className="form__form-group-field">
                <Field
                  name="sendToAllUsers"
                  component={renderCheckBoxField}
                  label={translate('sendToAllUsers')}
                  onChange={() => setSendToAllUsers(!sendToAllUsers)}
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.title.en ||
                  !formData.title.ar ||
                  !formData.body.en ||
                  !formData.body.ar ||
                  (!sendToAllUsers && !formData.userId)
                }
                onClick={addNotificationHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(HorizontalForm);
