import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { useLanguage } from '../../../../i18n/LanguageContext';
import { showDate } from '../../../../utils/functions';
import { UPLOADS_URL } from '../../../../config';

const HorizontalForm = ({ response, contracts }) => {
  const { isRTL, translate } = useLanguage();
  const { nameEn, nameAr, phoneNumber, userType, license, createdAt } =
    response;

  const renderFormGroup = (label, value) => (
    <div className="form__form-group">
      <span className="form__form-group-label">{translate(label)}</span>
      <div className="form__form-group-field">
        <input value={value} name={label} disabled />
      </div>
    </div>
  );

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal">
            {renderFormGroup('fullNameEn', nameEn)}
            {renderFormGroup('fullNameAr', nameAr)}
            {renderFormGroup('phoneNumber', phoneNumber)}

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('userType')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={
                    userType === 2
                      ? isRTL
                        ? 'شخصي'
                        : 'Personal'
                      : isRTL
                      ? 'شركة'
                      : 'Company'
                  }
                  disabled
                  name="userType"
                  component="input"
                  type="text"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('registeredOn')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={showDate(createdAt)}
                  name="approvalDate"
                  component="input"
                  type="text"
                  disabled
                />
              </div>
            </div>

            <h5 className="bold-text mb-4">{translate('userContracts')}</h5>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                margin: '0 0 20px 0',
                alignItems: 'center',
              }}
              className="bg-light p-3"
            >
              {contracts && contracts.length > 0 ? (
                <ul className="list-group w-50">
                  {contracts.map((contract, index) => (
                    <li key={index} className="list-group-item">
                      <a
                        href={`${UPLOADS_URL}uploads/${contract.fileName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="list-group-item list-group-item-action"
                      >
                        {contract.title}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{translate('noContracts')}</p>
              )}
            </div>

            {userType === 3 && (
              <div>
                <h5 className="bold-text mb-4">
                  {translate('companyLicense')}
                </h5>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    margin: '0 0 20px 0',
                  }}
                >
                  {license ? (
                    <a
                      href={`${UPLOADS_URL}uploads/${license}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-primary"
                    >
                      {license} <i className="bi bi-file-earmark-pdf"></i>
                    </a>
                  ) : (
                    `${translate('noLicense')}`
                  )}
                </div>
              </div>
            )}
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
