import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as Config from '../../config';

const ImageCarousel = ({ images }) => {
  const settings = {
    dots: images.length > 1,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div
      style={{
        width: '50%',
        padding: '10px 0',
        borderRadius: '25px',
      }}
    >
      <Slider {...settings}>
        {images?.map((item, index) => (
          <div key={index}>
            <img
              src={`${Config.UPLOADS_URL}uploads/${item}`}
              alt='estateImage'
              style={{
                height: 'auto',
                objectFit: 'contain',
                borderRadius: '25px',
                cursor: 'pointer',
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
