import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import { useLanguage } from '../../../i18n/LanguageContext';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const { isRTL, language, toggleLanguage } = useLanguage();

  return (
    <div className='topbar'>
      <div className='topbar__wrapper'>
        <div className={'topbar__flip'}>
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link
            className={
              isRTL ? 'topbar__logo logo__rtl' : 'topbar__logo logo__ltr'
            }
            to='/dashboard'
          />
        </div>
        <div
          className={
            isRTL ? 'topbar__flip topbar__button-container' : 'topbar__flip'
          }
        >
          <button
            type='button'
            className='topbar__button topbar__button--desktop language-button'
            onClick={() => toggleLanguage(language === 'en' ? 'ar' : 'en')}
          >
            {isRTL ? 'En' : 'Ar'}
          </button>
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
