import React from 'react';
import YouTube from 'react-youtube';

import { useLanguage } from '../../i18n/LanguageContext';

const extractVideoId = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=|\/v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const VideoPlayer = ({ videos }) => {
  const { translate } = useLanguage();

  const opts = {
    height: '230',
    width: '350',
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '16px',
      }}
    >
      {videos.map((url, index) => {
        const videoId = extractVideoId(url);
        return videoId ? (
          <div
            key={index}
            style={{
              height: '230px',
              width: '350px',
              borderRadius: '25px',
              overflow: 'hidden',
            }}
          >
            <YouTube videoId={videoId} opts={opts} onReady={onReady} />
          </div>
        ) : (
          <p key={index}>
            {translate('invalidVideo')}: {url}
          </p>
        );
      })}
    </div>
  );
};

export default VideoPlayer;
