import React from 'react';
import { useLanguage } from './../../i18n/LanguageContext';
import { Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { REQUEST_STATUS } from '../../utils/allDropdownData';
import * as Config from '../../config';

const RequestItem = ({ request, onAccept, onDelete }) => {
  const { translate, language } = useLanguage();
  const navigate = useNavigate();

  const handleActivate = () => {
    navigate(`/property/4/${request?.requestStatusId}/${request._id}`);
  };
  console.log(request);
  return (
    <>
      <div className="property-requests-table">
        <Link to={`/property/3/${request?.requestStatusId}/${request._id}`}>
          <div className="inner-container">
            <img
              className="profile"
              src={
                request?.userDetails?.image
                  ? `${Config.UPLOADS_URL}uploads/${request?.userDetails?.image}`
                  : require('../../assets/images/icons/avatar.jpg')
              }
              alt="profile"
              style={{
                borderRadius: '10%',
                margin: '10px',
                width: '70px',
                height: '70px',
                marginLeft: '30px',
              }}
            />
            <Table responsive style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th>
                    {language === 'en'
                      ? request?.userDetails?.nameEn
                      : request?.userDetails?.nameAr}
                  </th>
                  <th>{translate('governorate')}</th>
                  <th>{translate('area')}</th>
                  <th>{translate('block')}</th>
                  <th>{translate('streetTitle')}</th>
                  <th>{translate('homeTitle')}</th>
                  {/* <th>{translate('address')}</th> */}
                  <th>{translate('referenceTitle')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{request?.userDetails?.phoneNumber}</p>
                  </td>
                  <td>{request?.governorateDetails?.title?.[language]}</td>
                  <td>{request?.areaDetails?.title?.[language]}</td>
                  <td>
                    {request?.block && request?.block <= 0
                      ? ''
                      : request?.block}
                  </td>
                  <td>{request?.streetNo}</td>
                  <td>{request?.homeNo}</td>
                  {/* <td>{request?.location}</td> */}
                  <td>{request?.referenceNo}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Link>
        <div className="actions">
          {(request?.requestStatusId === REQUEST_STATUS.PENDING ||
            request?.requestStatusId === REQUEST_STATUS.REJECTED) && (
            <Button
              onClick={() => onAccept(request?._id)}
              color="success"
              style={{ padding: '5px 10px', background: 'green' }}
            >
              {translate('accept')}
            </Button>
          )}
          {(request?.requestStatusId === REQUEST_STATUS.PENDING ||
            request?.requestStatusId === REQUEST_STATUS.VISIT) && (
            <Button
              onClick={() => onDelete(request?._id)}
              color="danger"
              style={{ marginRight: '10px', padding: '5px 10px' }}
            >
              {translate('reject')}
            </Button>
          )}
          {request?.requestStatusId === REQUEST_STATUS.VISIT && (
            <Button
              onClick={handleActivate}
              color="success"
              style={{ marginRight: '10px', padding: '5px 10px' }}
            >
              {translate('activate')}
            </Button>
          )}
        </div>
      </div>
      <div
        className="line"
        style={{
          width: '97%',
          border: '1px solid rgba(96, 96, 96, 0.1)',
          margin: '10px auto',
        }}
      />
    </>
  );
};

export default RequestItem;
