export const COMMON_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  DELETED: 3,
  NOT_VERIFIED: 4,
};

export const PAYMENT_STATUS = {
  PENDING: 0,
  PAID: 1,
  UNPAID: 2,
  CANCELLED: 3,
};

export const PAID_PACKAGES_TYPE = {
  SUBSCRIBE: 0,
  PREMIUM_POST: 1,
};

export const MESSAGE_TYPE = {
  TEXT: 1,
  AUDIO: 2,
  VIDEO: 3,
  IMAGE: 4,
  FILE: 5,
};

export const REQUEST_TYPE = {
  RENT: 1,
  SELL: 2,
};

export const PROPERTY_CATEGORY = {
  RESIDENTIAL: 1,
  INVESTMENT: 2,
  COMMERICAL: 3,
  STATE_PROPERTY: 4,
  INDUSTRIAL: 5,
};

export const BUYER_SELLER_TYPE = {
  SINGLES: 1,
  INVESTOR: 2,
  COMPANY: 3,
  Family: 4,
};

export const REQUEST_STATUS = {
  APPROVED: 1,
  REJECTED: 2,
  VISIT: 3,
  ACTIVATED: 4,
  RENTED: 5,
  SOLD: 6,
  DELETED: 7,
  PENDING: 8,
};

export const REQUEST_TYPE_LOOKUP = [
  { title: { en: 'Rent', ar: 'ايجار' }, id: 1 },
  { title: { en: 'Sell', ar: 'بيع' }, id: 2 },
];

export const REQUEST_STATUS_LOOKUP = [
  { title: { en: 'Approved', ar: 'تم الموافقة' }, id: 1 },
  { title: { en: 'Rejected', ar: 'تم الرفض' }, id: 2 },
  { title: { en: 'ًWaiting For Visit', ar: 'بإنتظار الزيارة' }, id: 3 },
  { title: { en: 'Activated', ar: 'مفعل' }, id: 4 },
  { title: { en: 'Rented', ar: 'مستأجرة' }, id: 5 },
  { title: { en: 'Sold', ar: 'مباعة' }, id: 6 },
  { title: { en: 'Deleted', ar: 'محذوفة' }, id: 7 },
  { title: { en: 'Pending', ar: 'قيد الإنتظار' }, id: 8 },
];

export const PROPERTY_CATEGORY_LOOKUP = [
  { title: { en: 'Residential', ar: 'سكني' }, id: 1 },
  { title: { en: 'Investment', ar: 'استثماري' }, id: 2 },
  { title: { en: 'Commercial', ar: 'تجاري' }, id: 3 },
  { title: { en: 'ًState Property', ar: 'املاك دولة' }, id: 4 },
  { title: { en: 'ًّIndustrial', ar: 'صناعي' }, id: 5 },
];

export const BUYER_RENTER_TYPE_LOOKUP = [
  { title: { en: 'Singles', ar: 'عزاب' }, id: 1 },
  { title: { en: 'ًInvestor', ar: 'مستثمر' }, id: 2 },
  { title: { en: 'Company', ar: 'شركة' }, id: 3 },
  { title: { en: 'Family', ar: 'عائلي' }, id: 4 },
];

export const ACCOUNT_TYPE_LOOKUP = [
  { title: { en: 'Company', ar: 'شركة' }, id: 2 },
  { title: { en: 'User', ar: 'فردي' }, id: 3 },
];

export const DASHBOARD_CATEGORY_LOOKUP = [
  { title: { en: 'Estates', ar: 'عقارات' }, id: 2 },
  { title: { en: 'Services', ar: 'خدمات' }, id: 3 },
];

export const REQUEST_TYPE_LOOKUP2 = [
  { title: { en: 'for Rent', ar: 'للإيجار' }, id: 1 },
  { title: { en: 'for Sell', ar: 'للبيع' }, id: 2 },
];

export let countryData = [
  {
    en: 'Kuwait', //country name in english
    ar: 'Kuwait', //country name in arabic
    iso2: 'KW', //unique official ISO2 code of country
    // flag: require("@images/ic_kuwait.png") //country flag name saved in images folder
  },
];

export let userTypeData = [
  { en: 'Personal', ar: 'Personal', value: 1 },
  { en: 'Company', ar: 'Company', value: 2 },
];

export const userTypesEn = [
  { label: 'Personal', value: 2 },
  { label: 'Company', value: 3 },
];
export const userTypesAr = [
  { label: 'شخصي', value: 2 },
  { label: 'شركة', value: 3 },
];

export const durationOfRentEN = [
  { label: 'Yearly', value: 1 },
  { label: 'Daily', value: 2 },
];

export const durationOfRentAR = [
  { label: 'سنوي', value: 1 },
  { label: 'يومي', value: 2 },
];

export const nationalityEN = [
  { label: 'Kuwaiti', value: 1 },
  { label: 'Foreign', value: 2 },
];

export const nationalityAR = [
  { label: 'كويتي', value: 1 },
  { label: 'أجنبي', value: 2 },
];

export const locationByLineEN = [
  { label: 'First Line', value: 1 },
  { label: 'Second Line', value: 2 },
  { label: 'Third Line', value: 3 },
  { label: 'Fourth Line', value: 4 },
  { label: 'Fifth Line', value: 5 },
];

export const locationByLineAR = [
  { label: 'الخط الاول', value: 1 },
  { label: 'الخط الثاني', value: 2 },
  { label: 'الخط الثالث', value: 3 },
  { label: 'الخط الرابع', value: 4 },
  { label: 'الخط الخامس', value: 5 },
];

export const locationByStreetAR = [
  { label: 'شارع 1', value: 1 },
  { label: 'شارع 2', value: 2 },
  { label: 'شارع 3', value: 3 },
  { label: 'شارع 4', value: 4 },
  { label: 'شارع 5', value: 5 },
];

export const locationByStreetEN = [
  { label: 'Street 1', value: 1 },
  { label: 'Street 2', value: 2 },
  { label: 'Street 3', value: 3 },
  { label: 'Street 4', value: 4 },
  { label: 'Street 5', value: 5 },
];

export const iso2options = [
  {
    value: 1,
    label: 'Kuwait', //country name in english
    iso2: 'KW', //unique official ISO2 code of country
  },
];

export const governoratesEn = [
  { label: 'Hawally', value: 1 },
  { label: 'Al-Jhara', value: 2 },
];

export const governoratesAr = [
  { label: 'حولي', value: 1 },
  { label: 'الجهراء', value: 2 },
];

export const areasEn = [
  { label: 'Qadsiya', value: 1 },
  { label: 'Jabreya', value: 2 },
];

export const areasAr = [
  { label: 'القادسية', value: 1 },
  { label: 'الجابرية', value: 2 },
];

export const booleansAr = [
  { label: 'نعم', value: true },
  { label: 'لا', value: false },
];
export const booleansEn = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const propertyTypeEn = [
  { label: 'Residential', value: 1 },
  { label: 'Commercial', value: 2 },
];

export const propertyTypeAr = [
  { label: 'سكني', value: 1 },
  { label: 'تجاري', value: 2 },
];

export const propertyCategoryEn = [
  { label: 'Appartment', value: 1 },
  { label: 'Villa', value: 2 },
];

export const propertyCategoryAr = [
  { label: 'شقة', value: 1 },
  { label: 'فيلا', value: 2 },
];

export const serviceCategoryAr = [
  { label: 'بناء', value: 1 },
  { label: 'تنظيف', value: 2 },
  { label: 'اصباغ', value: 3 },
];

export const serviceCategoryEn = [
  { label: 'Constructing', value: 1 },
  { label: 'Cleaning', value: 2 },
  { label: 'Dyeing', value: 3 },
];

export const requestPurposeAr = [
  { label: 'ايجار', value: 1 },
  { label: 'بيع', value: 2 },
];

export const requestPurposeEn = [
  { label: 'Rent', value: 1 },
  { label: 'Sell', value: 2 },
];
