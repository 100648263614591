import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ThemeProps } from '../../shared/prop-types/ReducerProps';

function MainWrapper({ children }) {
  const theme = useSelector((state) => state.theme);

  return (
    <div className={theme.className}>
      <div className="wrapper">{children}</div>
    </div>
  );
}

MainWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainWrapper;
