import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { useLanguage } from '../../../i18n/LanguageContext';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/actions/authAction';

const SidebarContent = ({ onClick }) => {
  const { translate } = useLanguage();
  const dispatch = useDispatch();

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          title={translate('dashboard')}
          icon="chart-bars"
          route="/dashboard"
          onClick={() => onClick()}
        />

        <SidebarCategory title={translate('requests')} icon="file-add">
          <SidebarLink
            title={translate('requestsToAddProperty')}
            icon="file-add"
            route="/requestsAddProperty"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('requestsToVisitProperty')}
            icon="heart"
            route="/visitingRequests"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('rejectedRequests')}
            icon="trash"
            route="/rejectedRequests"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('properties')} icon="map-marker">
          <SidebarLink
            title={translate('propertiesList')}
            icon="map-marker"
            route="/properties"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('soldProperties')}
            icon="map-marker"
            route="/soldProperties"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('rentedProperties')}
            icon="map-marker"
            route="/rentedProperties"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('addProperty')}
            icon="pencil"
            route="/property/1/4/0"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('estateMarket')} icon="file-add">
          <SidebarLink
            title={translate('estateMarketList')}
            route="/estateMarket/list"
            icon="map-marker"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('addEstateMarket')}
            route="/estateMarket/add"
            icon="pencil"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('estateRequests')} icon="file-add">
          <SidebarLink
            title={translate('estateRequestsList')}
            route="/EstateRequestFind/list"
            icon="map-marker"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('users')} icon="users">
          <SidebarLink
            title={translate('usersList')}
            route="/users/list"
            icon="users"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('addUser')}
            route="/users/add"
            icon="user"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('admins')} icon="users">
          <SidebarLink
            title={translate('adminsList')}
            route="/admins/list"
            icon="users"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('addAdmin')}
            route="/admins/add"
            icon="user"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('notifications')} icon="alarm">
          <SidebarLink
            title={translate('notificationsList')}
            route="/notification/list"
            icon="alarm"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('sendNotifications')}
            route="notification/send"
            icon="alarm"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('services')} icon="phone">
          <SidebarLink
            title={translate('servicesList')}
            route="/services/list"
            icon="phone"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('addService')}
            route="/services/add"
            icon="phone"
            onClick={() => onClick()}
          />
        </SidebarCategory>

        <SidebarCategory title={translate('settings')} icon="cog">
          <SidebarLink
            title={translate('serviceCategories')}
            icon="location"
            route="/serviceCategory/list"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('governorates')}
            icon="location"
            route="/governorate/list"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('areas')}
            icon="location"
            route="/area/list"
            onClick={() => onClick()}
          />
          <SidebarLink
            title={translate('propertyTypes')}
            icon="location"
            route="/PropertyType/list"
            onClick={() => onClick()}
          />
          {/* <SidebarLink
            title={translate('filterKeys')}
            icon="location"
            route="/filterKey/list"
            onClick={() => onClick()}
          /> */}
          <SidebarLink
            title={translate('addons')}
            icon="location"
            route="/addon/list"
            onClick={() => onClick()}
          />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarLink
          title={translate('logout')}
          icon="exit"
          route="/"
          onClick={() => onClick(dispatch(logout()))}
        />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
