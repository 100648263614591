import React, { useState } from 'react';
import { useLanguage } from '../../i18n/LanguageContext';

const StringArray = ({ stringArray, setStringArray }) => {
  const { translate } = useLanguage();

  const [inputValue, setInputValue] = useState('');

  const handleAddString = () => {
    if (inputValue.trim() !== '') {
      setStringArray([...stringArray, inputValue]);
      setInputValue('');
    }
  };

  const handleDeleteString = (index) => {
    const newArray = stringArray.filter((_, i) => i !== index);
    setStringArray(newArray);
  };

  return (
    <div className='string-array-container'>
      <div className='input-container'>
        <input
          type='text'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={translate('enterAurl')}
          className='string-input'
        />
        <button onClick={handleAddString} className='add-button'>
          {translate('add')}
        </button>
      </div>
      <ul className='string-list'>
        {stringArray.map((str, index) => (
          <li key={index} className='string-item'>
            {str}
            <button
              onClick={() => handleDeleteString(index)}
              className='delete-button'
            >
              {translate('delete')}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StringArray;
