import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import axios from 'axios';

import * as Config from '../../../../../config';
import { useLanguage } from '../../../../../i18n/LanguageContext';
import { showToast } from '../../../../../utils/toast';
import { sessionExpire } from '../../../../../redux/actions/authAction';
import { useDispatch } from 'react-redux';

const HorizontalForm = ({ handleSubmit, reset, t }) => {
  const dispatch = useDispatch();
  const { translate } = useLanguage();
  const [formData, setFormData] = useState({
    key: '',
  });

  useEffect(() => {
    getItemHandler();
  }, []);

  const getItemHandler = async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');

    axios
      .get(`${Config.BASE_URL}Admin-FilterKey/filterKey/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            key: response.data.data?.key,
          });
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  const _onEditItemHandeller = async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');
    let dataToSend = {
      filterKeyId: id,
    };
    if (formData.key) {
      dataToSend.key = formData.key;
    }

    axios
      .put(`${Config.BASE_URL}Admin-FilterKey/editFilterKey`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          showToast(translate('updatedSuccessfully'), 'success');
          getItemHandler();
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          return showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          return error.response.data.message
            ? showToast(error.response.data.message, 'error')
            : showToast(String(error), 'error');
        }
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form
            className='form form--horizontal'
            onSubmit={handleSubmit(_onEditItemHandeller)}
          >
            <div className='form__form-group'>
              <span className='form__form-group-label'>{translate('key')}</span>
              <div className='form__form-group-field'>
                <input
                  value={formData?.key}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      key: e.target.value,
                    })
                  }
                  name='key'
                  component='input'
                  type='text'
                />
              </div>
            </div>

            <ButtonToolbar className='form__button-toolbar'>
              <Button color='primary' disabled={!formData.key} type='submit'>
                {translate('submit')}
              </Button>
              {/*<Button type="button" onClick={reset}>
                Cancel
              </Button>*/}
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
