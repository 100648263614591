import axios from 'axios';
import { BASE_URL } from './config';

export const getUserContracts = async (id, language) => {
  try {
    axios.defaults.headers.common['Accept-Language'] = language;
    const url = `${BASE_URL}Admin-Contract/listUserContracts?userId=${id}`;

    const response = await axios.get(url);
    return response;
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

export const addNewUserContract = async (dataToSend) => {
  try {
    const response = await axios.post(
      `${BASE_URL}Admin-Contract/uploadContract`,
      dataToSend
    );
    return response;
  } catch (error) {
    throw error;
  }
};
