import { useDispatch } from 'react-redux';
import { showToast } from './toast';
import { useLanguage } from '../i18n/LanguageContext';
import { sessionExpire } from '../redux/actions/authAction';

const useErrorHandling = () => {
  const { translate } = useLanguage();
  const dispatch = useDispatch();

  const handleError = (error) => {
    if (!error.response) {
      return showToast(String(error), 'error');
    } else if (error.response.status === 401) {
      showToast(translate('sessionExpire'), 'error');
      dispatch(sessionExpire());
    } else {
      return showToast(error.response.data.message || String(error), 'error');
    }
  };
  return handleError;
};

export default useErrorHandling;
