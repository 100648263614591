import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Table from './components/DataTable';
import { useLanguage } from '../../../i18n/LanguageContext';
import { useSelector } from 'react-redux';

const ListTable = ({ t }) => {
  const { translate } = useLanguage();
  const lookups = useSelector((state) => state.auth.lookups);

  const governorates = lookups.governorates;
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{translate('estateMarketList')}</h3>
        </Col>
      </Row>
      <Row>
        {governorates.length > 0 &&
          governorates.map((val, i) => {
            return val && <Table governorate={val} />;
          })}
      </Row>
    </Container>
  );
};

ListTable.propTypes = {
  t: PropTypes.func,
};

export default ListTable;
