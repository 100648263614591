import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import HorizontalForm from './components';
import { useLanguage } from '../../../i18n/LanguageContext';
import { UserAPI, ContractAPI } from '../../../apis';
import { UPLOADS_URL } from '../../../config';

const ViewForm = () => {
  const { translate, language } = useLanguage();
  const url_string = document.URL;
  const url = new URL(url_string);
  const id = url.searchParams.get('id');
  const [user, setUser] = useState({});
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserAPI.getUserById(id, language);
        const userContracts = await ContractAPI.getUserContracts(id, language);

        setUser(response.data.data);
        setContracts(userContracts.data.data);
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    fetchData();
  }, [id, language]);

  return (
    <Container>
      <Row>
        <Col
          md={12}
          className='d-flex justify-content-between align-items-center p-4'
        >
          <h3 className='page-title'>{translate('viewUser')}</h3>
          {user.image ? (
            <img
              src={`${UPLOADS_URL}uploads/${user.image}`}
              alt='profile'
              className='img-fluid border'
              style={{
                height: '100px',
                width: '90px',
                borderRadius: '50%',
              }}
            />
          ) : (
            <img
              src={require('../../../assets/images/icons/avatar.jpg')}
              alt=''
              className='img-fluid border'
              style={{
                height: '100px',
                width: '90px',
                borderRadius: '50%',
              }}
            />
          )}
        </Col>
      </Row>
      <Row>
        <HorizontalForm response={user} contracts={contracts} />
      </Row>
    </Container>
  );
};

ViewForm.propTypes = {
  t: PropTypes.func,
};

export default ViewForm;
