import axios from 'axios';
import { BASE_URL } from './config';

export const getAllFilterKeys = async (status) => {
  const url = `${BASE_URL}Admin-FilterKey/listFilterKeys?status=${status}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
