/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';
import { useDispatch } from 'react-redux';

import { showDate } from '../../../../utils/functions';
import { showToast } from '../../../../utils/toast';
import { sessionExpire } from '../../../../redux/actions/authAction';
import Panel from '../../../../shared/components/PanelWithExcel';
import Search from '../../../../shared/components/Search';
import Loader from '../../../../containers/Loader/index';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { NotificationAPI } from '../../../../apis';

const DataTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isRTL, translate } = useLanguage();

  const [state, setState] = useState({
    id: '',
    items: [],
    pageRange: 5,
    page: 1,
    perPage: 10,
    totalItemCount: 0,
    alertStatus: false,
    alertDelete: false,
    searchField: '',
    isLoading: false,
  });

  const itemsListing = async (searchField, page) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const response = await NotificationAPI.listAllNotifications(
        searchField,
        page,
        state.perPage
      );
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          items: response.data.data.listing.reverse(),
          totalItemCount: response.data.data.count,
          isLoading: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return showToast(response.data.message || translate('error'), 'error');
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (!error.response) {
        return showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
        dispatch(sessionExpire());
      } else {
        return showToast(error.response.data.message || String(error), 'error');
      }
    }
  };

  useEffect(() => {
    itemsListing(state.searchField, state.page);
  }, []);

  const handlePageChange = (pageNumber) => {
    setState((prevState) => ({ ...prevState, page: pageNumber }));
    itemsListing(state.searchField, pageNumber);
  };

  const setSearchField = (field) => {
    setState((prevState) => ({
      ...prevState,
      searchField: field,
    }));
    itemsListing(field, state.page);
  };

  return (
    <div className='card'>
      <div className='content'>
        <Panel
          xl={12}
          lg={12}
          md={12}
          xs={12}
          title=''
          subhead=''
          refreshFunc={() => itemsListing(state.searchField, state.page)}
          addFunc={() => {
            navigate('/notification/send');
          }}
          placement={isRTL ? 'rtl' : 'ltr'}
        >
          <Search
            placeholder={translate('searchByTitle')}
            searchField={state.searchField}
            setSearchField={setSearchField}
            search={() => itemsListing(state.searchField, state.page)}
          />
          <div dir={isRTL ? 'rtl' : 'ltr'}>
            {state.isLoading && <Loader />}
            <ResponsiveContainer className='dashboard__area'>
              <table className='table table-striped '>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{translate('notificationTitle')}</th>
                    <th>{translate('notificationBody')}</th>
                    <th>
                      <div className='table-search cursor-pointer'>
                        {translate('receiversNumber')}
                      </div>
                    </th>
                    <th>{translate('registeredOn')}</th>
                  </tr>
                </thead>
                <tbody>
                  {state?.items?.length > 0 &&
                    state.items.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {i +
                              (state.page * state.perPage - state.perPage) +
                              1}
                          </td>
                          <td>{isRTL ? val?.title.ar : val.title.en}</td>
                          <td>{isRTL ? val?.body.ar : val.body.en}</td>
                          <td>{val?.receivers}</td>
                          <td>{showDate(val.createdAt)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </ResponsiveContainer>
            <div className='row'>
              <div className='col-md-12'>
                <Pagination
                  activePage={state.page}
                  itemsCountPerPage={state.perPage}
                  totalItemsCount={state.totalItemCount}
                  pageRangeDisplayed={state.pageRange}
                  onChange={(n) => handlePageChange(n)}
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default DataTable;
