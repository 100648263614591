import React, { createContext, useContext, useState, useEffect } from 'react';
import en from './en.json';
import ar from './ar.json';

export const translations = {
  en: en,
  ar: ar,
};

export const LanguageContext = createContext(translations.english);

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LangaugeProvider = ({ children }) => {
  const [isRTL, setIsRTL] = useState(() => {
    const storedIsRTL = localStorage.getItem('isRTL');
    return storedIsRTL === null ? true : storedIsRTL === 'true';
  });

  const [language, setLanguage] = useState(() => {
    const storedLang = localStorage.getItem('language');
    return storedLang ? storedLang : 'ar';
  });

  const toggleLanguage = (language) => {
    console.log(language);
    setLanguage(language);
    setIsRTL(language === 'ar' ? true : false);
  };

  const translate = (key = '') => {
    if (translations[language] && translations[language][key]) {
      return translations[language][key];
    } else {
      return key;
    }
  };

  useEffect(() => {
    localStorage.setItem('language', language);
    localStorage.setItem(
      'isRTL',
      language === 'ar' ? true.toString() : false.toString()
    );
  }, [language]);

  useEffect(() => {
    const rootHtml = document.getElementById('root-html');
    if (rootHtml) {
      rootHtml.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
    }
  }, [isRTL]);

  return (
    <LanguageContext.Provider
      value={{ isRTL, language, toggleLanguage, translate }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
