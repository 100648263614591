import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';

import { useLanguage } from '../../../../../i18n/LanguageContext';
import { showDate } from '../../../../../utils/functions';
import { showToast } from '../../../../../utils/toast';
import { ServiceCategoryAPI } from '../../../../../apis';
import useErrorHandling from '../../../../../utils/error';

const HorizontalForm = () => {
  const { translate } = useLanguage();
  const handleError = useErrorHandling();

  const [formData, setFormData] = useState({
    title: null,
    createdAt: null,
  });

  useEffect(() => {
    getItemHandler();
  }, []);

  const getItemHandler = async () => {
    let url_string = document.URL;
    let url = new URL(url_string);
    let id = url.searchParams.get('id');

    try {
      const response = await ServiceCategoryAPI.getServiceCategory(id);
      if (response.status === 200) {
        setFormData({
          title: {
            en: response.data.data?.title?.en,
            ar: response.data.data?.title?.ar,
          },
          createdAt: response.data.data?.createdAt,
        });
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className='form form--horizontal'>
            <div className='form__form-group'>
              <span className='form__form-group-label'>
                {translate('enTitle')}
              </span>
              <div className='form__form-group-field'>
                <input
                  value={formData?.title?.en}
                  disabled
                  name='firstname'
                  component='input'
                  type='text'
                />
              </div>
            </div>
            <div className='form__form-group'>
              <span className='form__form-group-label'>
                {translate('arTitle')}
              </span>
              <div className='form__form-group-field'>
                <input
                  value={formData?.title?.ar}
                  disabled
                  name='arTitle'
                  component='input'
                  type='text'
                />
              </div>
            </div>

            <div className='form__form-group'>
              <span className='form__form-group-label'>
                {translate('createdAt')}
              </span>
              <div className='form__form-group-field'>
                <input
                  value={
                    formData?.createdAt ? showDate(formData.createdAt) : ''
                  }
                  name='createdAt'
                  component='input'
                  type='text'
                  disabled
                />
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);
