import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { ResponsiveContainer } from 'recharts';

import { showToast } from '../../../utils/toast';
import Panel from '../../../shared/components/Panel';
import Search from '../../../shared/components/Search';
import Loader from '../../../containers/Loader/index';
import { ServiceAPI } from '../../../apis';
import { useLanguage } from '../../../i18n/LanguageContext';
import ServiceItem from '../../../shared/components/ServiceItem';
import useErrorHandling from '../../../utils/error';
import SweetAlert from 'react-bootstrap-sweetalert';

const Services = () => {
  const { isRTL, translate } = useLanguage();
  const handleError = useErrorHandling();

  const [state, setState] = useState({
    id: '',
    items: [],
    isLoading: false,
    totalItemCount: 0,
    pageRange: 5,
    page: 1,
    perPage: 10,
    alertReject: false,
    alertApprove: false,
    alertDelete: false,
    searchField: '',
  });

  useEffect(() => {
    getAllServices('', state.searchField, state.page);
  }, []);

  const getAllServices = async (serviceCategoryId, searchField, pageNumber) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const response = await ServiceAPI.getAllServices(
        serviceCategoryId,
        searchField,
        pageNumber,
        state.perPage
      );
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          items: response.data.data.listing,
          totalItemCount: response.data.data.count,
          isLoading: false,
        }));
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      handleError(error);
    }
  };

  const setSearchField = (field) => {
    setState((prevState) => ({
      ...prevState,
      searchField: field,
    }));
    getAllServices('', field, state.page);
  };

  const handlePageChange = (pageNumber) => {
    setState((prevState) => ({ ...prevState, page: pageNumber }));
    getAllServices('', state.searchField, state.page);
  };

  const onDeleteHandler = async (id) => {
    setState((prevState) => ({
      ...prevState,
      alertDelete: true,
      id: id,
    }));
  };

  const onConfirmDelete = async (val) => {
    if (val) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await ServiceAPI.deleteService(state.id);
      if (response.status === 200) {
        getAllServices('', state.searchField, state.page);
        setState((prevState) => ({
          ...prevState,
          alertDelete: false,
          isLoading: false,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        alertDelete: false,
        isLoading: false,
      }));
    }
  };

  return (
    <>
      <div className="card">
        <div className="content">
          <Panel
            xl={12}
            lg={12}
            md={12}
            xs={12}
            title=""
            subhead=""
            refreshFunc={() =>
              getAllServices('', state.searchField, state.page)
            }
            withAddFunc={false}
            placement={isRTL ? 'rtl' : 'ltr'}
          >
            <Search
              placeholder={translate('searchByTitle')}
              searchField={state.searchField}
              setSearchField={setSearchField}
              search={() => {
                getAllServices('', state.searchField, state.page);
              }}
            />
            <div dir={isRTL ? 'rtl' : 'ltr'}>
              {state.isLoading && <Loader />}
              <ResponsiveContainer className="dashboard__area">
                <div className="add-property-page--content">
                  <div
                    className="requests-header d-flex justify-content-between"
                    style={{ marginBottom: '20px' }}
                  >
                    <h1>{translate('services')}</h1>
                  </div>
                  {state?.items?.map((request) => {
                    return (
                      <ServiceItem
                        request={request}
                        onDelete={onDeleteHandler}
                      />
                    );
                  })}
                </div>
              </ResponsiveContainer>
              <SweetAlert
                danger
                showCancel
                custom
                confirmBtnText={translate('yes')}
                show={state.alertDelete}
                cancelBtnText={translate('no')}
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                btnSize="sm"
                title={translate('deleteAlert')}
                onConfirm={() => onConfirmDelete(true)}
                onCancel={() => onConfirmDelete(false)}
              ></SweetAlert>
              <div className="row">
                <div className="col-md-12">
                  <Pagination
                    activePage={state.page}
                    itemsCountPerPage={state.perPage}
                    totalItemsCount={state.totalItemCount}
                    pageRangeDisplayed={state.pageRange}
                    onChange={(n) => handlePageChange(n)}
                  />
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </>
  );
};

export default Services;
